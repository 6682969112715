<template>
  <i18n-t keypath="faq.hotel.answer" tag="p" scope="global">
    <a href="https://www.hotelhaarlem.nl/kamer-boeken/" target="_blank"
      >Van der Valk Hotel Haarlem</a
    >
    <router-link to="/contact">{{ $t('faq.hotel.us') }}</router-link>
  </i18n-t>
</template>

<style lang="scss" scoped>
a {
  color: $faq-link;
}
</style>
