/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GuestRequest } from './GuestRequest';
import {
    GuestRequestFromJSON,
    GuestRequestFromJSONTyped,
    GuestRequestToJSON,
} from './GuestRequest';

/**
 * 
 * @export
 * @interface GuestBulkRequest
 */
export interface GuestBulkRequest {
    /**
     * 
     * @type {boolean}
     * @memberof GuestBulkRequest
     */
    acceptedInvite: boolean;
    /**
     * 
     * @type {Array<GuestRequest>}
     * @memberof GuestBulkRequest
     */
    guests: Array<GuestRequest>;
}

/**
 * Check if a given object implements the GuestBulkRequest interface.
 */
export function instanceOfGuestBulkRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "acceptedInvite" in value;
    isInstance = isInstance && "guests" in value;

    return isInstance;
}

export function GuestBulkRequestFromJSON(json: any): GuestBulkRequest {
    return GuestBulkRequestFromJSONTyped(json, false);
}

export function GuestBulkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestBulkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptedInvite': json['accepted_invite'],
        'guests': ((json['guests'] as Array<any>).map(GuestRequestFromJSON)),
    };
}

export function GuestBulkRequestToJSON(value?: GuestBulkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accepted_invite': value.acceptedInvite,
        'guests': ((value.guests as Array<any>).map(GuestRequestToJSON)),
    };
}

