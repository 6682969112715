import { defineStore } from 'pinia';
import { User } from '@/client';
import { UserApi, ResponseError } from '@/client';
import setupClient from '@/setupClient';
import router from '@/router';

export const useStore = defineStore('user', {
  state: () => {
    return {
      // User gets always initialized so we use the unknown hack to not have to
      // worry about null.
      user: null as unknown as User,
    };
  },
});

export async function initStore() {
  const store = useStore();

  const userApi = new UserApi();
  try {
    store.user = await userApi.userRetrieve();
  } catch (e) {
    if (e instanceof ResponseError && e.response.status == 403) {
      return false;
    }
    throw e;
  } finally {
    setupClient();
  }

  if (store.user.fullGuest === null) {
    router.push({ name: 'codeword' });
  }
  return true;
}
