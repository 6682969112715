<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { LogoutApi } from '@/client';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import setupClient from '@/setupClient';
import ArrowSvg from '@/assets/images/img_icon_arrow.svg?component';
const { locale } = useI18n({ useScope: 'global' });

function switchLanguage(language: string) {
  localStorage.setItem('locale', language);
  setupClient(language);
  locale.value = language;
}

const props = defineProps<{ loggedIn: boolean }>();

const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

async function logout() {
  const logoutApi = new LogoutApi();
  await logoutApi.logout();
  toast.success(t('loggedOut'));
  router.push({ name: 'login' });
}
console.log(props);
</script>

<template>
  <div
    class="language-settings"
    :class="{
      'logged-in': props.loggedIn,
      nl: $i18n.locale === 'nl',
      en: $i18n.locale === 'en',
    }"
  >
    <div v-if="props.loggedIn" class="logout" @click="logout">
      <ArrowSvg />{{ $t('logout') }}
    </div>
    <span v-if="loggedIn">|</span>
    <img
      class="dutch"
      :class="{ selected: $i18n.locale === 'nl' }"
      src="@/assets/images/nl.svg?url"
      alt="dutch"
      @click="switchLanguage('nl')"
    />
    <img
      class="english"
      :class="{ selected: $i18n.locale === 'en' }"
      src="@/assets/images/gb.svg?url"
      alt="english"
      @click="switchLanguage('en')"
    />
  </div>
</template>

<style lang="scss" scoped>
.language-settings {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  width: auto;
  top: 0;
  right: 15%;
  background-color: white;
  padding: $space-s;
  border-radius: 0 0 $radius10 $radius10;
  z-index: 16;
  box-shadow: 0px 0px 5px 0px grey;
  color: $golden-light;

  @include tablet-portrait {
    &.logged-in {
      &.en {
        width: 148px;
      }
      &.nl {
        width: 168px;
      }
    }
  }

  @include mobile {
    right: 3%;
  }

  .logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-left: 4px;

    @include mobile {
      display: none;
    }

    &:hover,
    &:active {
      font-size: 18px;
      margin-left: 0;
      margin-right: -5px;
    }

    svg {
      height: 20px;
      width: 20px;
      transform: rotate(180deg);
      margin-right: $space-xxs;
      fill: $golden-light;
    }
  }

  span {
    padding: 0 $space-s;
    @include mobile {
      display: none;
    }
  }

  img {
    cursor: pointer;
    height: 25px;
    width: 25px;
    object-fit: cover;
    border-radius: $radius-round;
    border: 2px solid white;

    &.selected {
      display: none;
    }

    &:hover,
    &:active {
      border: none;
    }
  }
}
</style>
