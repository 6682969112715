<template>
  <PageTitle>Privacy Policy</PageTitle>
  <div class="privacy-policy">
    {{
      `
Jelte.li is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.

Contactgegevens:
jelteli-privacy@jeltef.nl

Persoonsgegevens die wij verwerken
Jelte.li verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.

Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:
- Voor- en achternaam
- E-mailadres
- IP-adres
- Overige persoonsgegevens die je actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch
- Gegevens over jouw activiteiten op onze website


Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via jelteli-privacy@jeltef.nl, dan verwijderen wij deze informatie.


Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
Jelte.li verwerkt jouw persoonsgegevens voor de volgende doelen:
- Verzenden van updates over de bruiloft
- Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren
- Je de mogelijkheid te bieden een account aan te maken


Geautomatiseerde besluitvorming
Jelte.li neemt #responsibility op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Jelte.li) tussen zit. Jelte.li gebruikt de volgende computerprogramma's of -systemen:

#use_explanation


Hoe lang we persoonsgegevens bewaren

Jelte.li bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:

Maximaal tot een jaar na de bruiloft: 7 juli 2024. Zodat we je een link naar de fotos en videos kunnen sturen.

Delen van persoonsgegevens met derden

Jelte.li verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Jelte.li blijft verantwoordelijk voor deze verwerkingen.


Cookies, of vergelijkbare technieken, die wij gebruiken
Jelte.li gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.


Gegevens inzien, aanpassen of verwijderen
Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Jelte.li en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.

Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar jelteli-privacy@jeltef.nl.

Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek .

Jelte.li wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons



Hoe wij persoonsgegevens beveiligen
Jelte.li neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze klantenservice of via jelteli-privacy@jeltef.nl. Jelte.li heeft de volgende maatregelen genomen om jouw persoonsgegevens te beveiligen:

[voeg hier eventueel andere maatregelen die je neemt aan toe]
- TLS (voorheen SSL) Wij versturen jouw gegevens via een beveiligde internetverbinding. Dit kun je zien aan de adresbalk 'https' en het hangslotje in de adresbalk.
`
    }}
  </div>
</template>
<style lang="scss" scoped>
.privacy-policy {
  white-space: pre-wrap;
}
</style>
