<script setup lang="ts">
import { ref, onErrorCaptured } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import NavigationBarMobile from '@/components/NavigationBarMobile.vue';
import LanguageMenu from '@/components/LanguageMenu.vue';
import Page from '@/components/Page.vue';

const error = ref(false);
const route = useRoute();

onErrorCaptured(() => {
  error.value = true;

  return true;
});

function isLogin() {
  return [
    'login',
    'google-login',
    'register',
    'reset-password',
    'reset-password-confirm',
    'codeword',
    'not-found',
    'teaser',
  ].includes(route.name as string);
}
</script>

<template>
  <RouterView v-slot="{ Component }">
    <template v-if="Component">
      <LanguageMenu :logged-in="!isLogin() || route.name == 'codeword'" />
      <NavigationBarMobile v-if="!isLogin()" />
      <main class="v-theme--light">
        <Suspense>
          <component :is="Page" v-if="!isLogin()">
            <component :is="Component" />
          </component>
          <component :is="Component" v-else />
          <template #fallback>
            <div class="simple-body">
              <h1 v-if="error">
                Something went wrong when loading this page :(
              </h1>
              <h1 v-else>Loading...</h1>
              <p v-if="error">
                Please try if refreshing solves it. If that doesn't help try
                reaching out to us directly.
              </p>
            </div>
          </template>
        </Suspense>
      </main>
    </template>
  </RouterView>
</template>
