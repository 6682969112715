<script setup lang="ts">
import DresscodeSvg from '@/assets/images/img_faq_dresscode.svg?component';
import MediaSvg from '@/assets/images/img_faq_media.svg?component';
import PresentsSvg from '@/assets/images/img_faq_presents.svg?component';
import TransportSvg from '@/assets/images/img_faq_transport.svg?component';
import ParkingSvg from '@/assets/images/img_faq_parking.svg?component';
import HotelSvg from '@/assets/images/img_faq_hotel.svg?component';
import TaxiSvg from '@/assets/images/img_faq_taxi.svg?component';
import ActSvg from '@/assets/images/img_faq_act.svg?component';
import SocialMediaSvg from '@/assets/images/img_faq_social_media.svg?component';
import BubblesSvg from '@/assets/images/img_faq_bubbles.svg?component';
import ChildSvg from '@/assets/images/img_faq_child.svg?component';
import PartnerSvg from '@/assets/images/img_faq_partner.svg?component';
import FaqActAnswer from '@/components/FaqActAnswer.vue';
import FaqParkingAnswer from '@/components/FaqParkingAnswer.vue';
import FaqHotelAnswer from '@/components/FaqHotelAnswer.vue';
import FaqPartnerAnswer from '@/components/FaqPartnerAnswer.vue';
import PageTitle from '@/components/PageTitle.vue';

const FAQItems = [
  {
    topic: 'dresscode',
    icon: DresscodeSvg,
  },
  {
    topic: 'media',
    icon: MediaSvg,
  },
  {
    topic: 'presents',
    icon: PresentsSvg,
  },
  {
    topic: 'transport',
    icon: TransportSvg,
  },
  {
    topic: 'parking',
    answerComponent: FaqParkingAnswer,
    icon: ParkingSvg,
  },
  {
    topic: 'hotel',
    answerComponent: FaqHotelAnswer,
    icon: HotelSvg,
  },
  {
    topic: 'taxi',
    icon: TaxiSvg,
  },
  {
    topic: 'act',
    answerComponent: FaqActAnswer,
    icon: ActSvg,
  },
  {
    topic: 'socialmedia',
    icon: SocialMediaSvg,
  },
  {
    topic: 'bubbles',
    icon: BubblesSvg,
  },
  {
    topic: 'child',
    icon: ChildSvg,
  },
  {
    topic: 'partner',
    answerComponent: FaqPartnerAnswer,
    icon: PartnerSvg,
  },
];
</script>

<template>
  <PageTitle>FAQ</PageTitle>
  <div class="faq-container">
    <div class="faq-items">
      <div v-for="(item, index) in FAQItems" :key="index" class="faq-item">
        <component
          :is="item.icon"
          class="icon"
          :alt="item.topic + `icon`"
        ></component>
        <p class="faq">{{ $t(`faq.${item.topic}.faq`) }}</p>
        <component
          :is="item.answerComponent"
          v-if="item.answerComponent"
        ></component>
        <p v-else class="answer">{{ $t(`faq.${item.topic}.answer`) }}</p>
      </div>
    </div>
    <p class="other">
      <i18n-t keypath="faq.notIncluded.text" scope="global">
        <router-link to="/contact">Contact</router-link>
      </i18n-t>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.faq-container {
  display: flex;
  flex-direction: column;

  p.other {
    font-size: 18px;
    margin-top: $space-l;
  }

  @include mobile {
    p.other {
      margin: 0;
      padding: $space-s;
    }
  }
}
.faq-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include tablet-portrait {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row; /* wrap the items to the next row */
    gap: $space-xm 1.5%;
  }

  @include tablet-landscape {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row; /* wrap the items to the next row */
  }

  .faq-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 300px;
    padding: $space-xs;
    background-color: $golden-faq-items;

    @include mobile {
      width: 90%;
      margin-bottom: $space-xm;
    }

    p {
      margin-top: $space-s;

      @include mobile {
        font-size: 18px;
      }
    }

    .faq {
      font-weight: bold;
    }

    svg {
      height: 60px;
      width: 60px;
      margin-top: $space-l;
      fill: white;
    }
  }
}
</style>
