/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Present,
  PresentClaimRequest,
} from '../models';
import {
    PresentFromJSON,
    PresentToJSON,
    PresentClaimRequestFromJSON,
    PresentClaimRequestToJSON,
} from '../models';

export interface PresentClaimOperationRequest {
    id: number;
    presentClaimRequest: PresentClaimRequest;
}

/**
 * 
 */
export class PresentApi extends runtime.BaseAPI {

    /**
     */
    async presentClaimRaw(requestParameters: PresentClaimOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling presentClaim.');
        }

        if (requestParameters.presentClaimRequest === null || requestParameters.presentClaimRequest === undefined) {
            throw new runtime.RequiredError('presentClaimRequest','Required parameter requestParameters.presentClaimRequest was null or undefined when calling presentClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/presents/claim/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PresentClaimRequestToJSON(requestParameters.presentClaimRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async presentClaim(requestParameters: PresentClaimOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.presentClaimRaw(requestParameters, initOverrides);
    }

    /**
     */
    async presentListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Present>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/presents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PresentFromJSON));
    }

    /**
     */
    async presentList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Present>> {
        const response = await this.presentListRaw(initOverrides);
        return await response.value();
    }

}
