<script setup lang="ts">
import Profile from '@/components/Profile.vue';
import Sui from '@/assets/images/img_profile_sui.jpeg';
import Li from '@/assets/images/img_profile_li.jpeg';
import Jelte from '@/assets/images/img_profile_jelte.jpeg';
import { ContactApi } from '@/client';
import PageTitle from '@/components/PageTitle.vue';

const contactApi = new ContactApi();
const initialcontacts = await contactApi.contactList();

console.log('contacts: ', initialcontacts);

const profiles = {
  mc: {
    ...initialcontacts[0],
    img: Sui,
  },
  bride: {
    ...initialcontacts[1],
    img: Li,
  },
  groom: {
    ...initialcontacts[2],
    img: Jelte,
  },
};
</script>

<template>
  <PageTitle>Contact</PageTitle>
  <div class="contact-container">
    <Profile v-bind="profiles.mc" type="mc" />
    <div class="couple">
      <Profile v-bind="profiles.bride" type="bride" />
      <Profile v-bind="profiles.groom" type="groom" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contact-container {
  .couple {
    :deep(h1) {
      display: none;
    }

    @include desktop {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
</style>
