/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginRequest,
  Redirect,
  RedirectRequest,
  SocialLogin,
  SocialLoginRequest,
  Token,
} from '../models';
import {
    LoginRequestFromJSON,
    LoginRequestToJSON,
    RedirectFromJSON,
    RedirectToJSON,
    RedirectRequestFromJSON,
    RedirectRequestToJSON,
    SocialLoginFromJSON,
    SocialLoginToJSON,
    SocialLoginRequestFromJSON,
    SocialLoginRequestToJSON,
    TokenFromJSON,
    TokenToJSON,
} from '../models';

export interface LoginOperationRequest {
    loginRequest: LoginRequest;
}

export interface LoginGoogleRequest {
    socialLoginRequest?: SocialLoginRequest;
}

export interface LoginGoogleUrlRequest {
    redirectRequest?: RedirectRequest;
}

/**
 * 
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     */
    async loginRaw(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
            throw new runtime.RequiredError('loginRequest','Required parameter requestParameters.loginRequest was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/login/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     */
    async login(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
     */
    async loginGoogleRaw(requestParameters: LoginGoogleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialLogin>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/login/google`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialLoginRequestToJSON(requestParameters.socialLoginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialLoginFromJSON(jsonValue));
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
     */
    async loginGoogle(requestParameters: LoginGoogleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialLogin> {
        const response = await this.loginGoogleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginGoogleUrlRaw(requestParameters: LoginGoogleUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Redirect>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/login/google/url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RedirectRequestToJSON(requestParameters.redirectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectFromJSON(jsonValue));
    }

    /**
     */
    async loginGoogleUrl(requestParameters: LoginGoogleUrlRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Redirect> {
        const response = await this.loginGoogleUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
