<template>
  <div></div>
</template>

<script setup lang="ts">
import { RegistrationApi } from '../client/apis/RegistrationApi.js';
import { ResponseError } from '@/client/runtime';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

const registrationApi = new RegistrationApi();
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

let validToken = true;
if (token) {
  try {
    await registrationApi.registrationVerifyEmail({
      verifyEmailRequest: {
        key: token,
      },
    });
  } catch (e) {
    if (e instanceof ResponseError) {
      if (e.response.status == 404) {
        validToken = false;
      } else {
        throw e;
      }
    } else {
      throw e;
    }
  }
} else {
  validToken = false;
}
if (validToken) {
  toast.success(t('registerSuccess'));
} else {
  toast.error(t('registerFail'));
}
router.push({ name: 'home' });
</script>
