export default {
  hello: 'hallo wereld!',
  login: 'Inloggen',
  loginTo: 'Log in op',
  or: 'Of',
  loggedIn: 'Je bent nu ingelogd',
  logout: 'Uitloggen',
  loggedOut: 'Je bent nu uitgelogd',
  program: 'Programma',
  username: 'Gebruikersnaam',
  email: 'Email',
  password: 'Wachtwoord',
  password1: 'Wachtwoord',
  password2: 'Herhaal wachtwoord',
  newPassword1: 'Nieuw wachtwoord',
  newPassword2: 'Herhaal nieuw wachtwoord',
  codeword: 'Codewoord',
  codewordHint: 'Deze staat op je uitnodiging',
  codewordTitle: 'Codewoord invullen',
  youFoundIt: 'Goed gedaan, je hebt hem gevonden!',
  forgotPassword: 'Wachtwoord vergeten?',
  resetPassword: 'Reset wachtwoord',
  getResetEmail:
    'Binnen een paar minuten zou je een email moeten krijgen met een wachtwoord reset link.',
  passwordResetSuccess: 'Je wachtwoord is aangepast. Je kan nu weer inloggen',
  register: 'Registreer',
  registerFor: 'Registreer voor',
  googleLogin: 'Inloggen met Google',
  noAccount: 'Geen account?',
  createAccount: 'Maak deze hier aan!',
  alreadyAccount: 'Al een account?',
  signin: 'Log hier in',
  registerSuccess: `Jouw email is hierbij bevestigd!`,
  registerFail: `De email bevestigingslink is ongeldig. Misschien heb je hem al een keer gebruikt.`,
  gettingMarried: 'Wij gaan trouwen!',
  moreConfetti: 'Meer confetti!',
  days: 'Dagen',
  hours: 'Uren',
  minutes: 'Minuten',
  seconds: 'Seconden',
  until: 'nog te gaan tot',
  latestNews: 'Laatste Nieuws',
  readMore: 'Lees meer hier!',
  updates: {
    welcome: {
      title: `Welkom op Jelte.Li!`,
    },
    dance: {
      title: `Doe mee aan onze openingsdans!`,
    },
  },
  welcomeTextFull: {
    text: `Hoera! Het is eindelijk zover, wij gaan trouwen op vrijdag 7 juli 2023! Jij bent van harte
    uitgenodigd om dit speciale moment met ons te vieren. Eerst wat praktische zaken: ga naar de {0}
    pagina om jezelf (en jouw gasten) aan te melden. Meer informatie kun je vinden op de {1} en {2}
    pagina's en mocht je vraag er niet tussen staan, stuur dan een bericht naar één van de {3}.
    We zullen deze Home pagina ook gebruiken om meer nieuws te delen de komende tijd, dus meer volgt nog!`,
    contact: `contact personen`,
  },
  welcomeTextParty: {
    text: `Hoera! Het is eindelijk zover, wij gaan trouwen op vrijdag 7 juli 2023! Jij bent van harte
    uitgenodigd voor het eindfeest om dit speciale moment met ons te vieren. Eerst wat praktische zaken:
    ga naar de {0} pagina om jezelf (en jouw gasten) aan te melden. Eerst wat praktische zaken: ga naar
    de {0} pagina om jezelf (en jouw gasten) aan te melden. Meer informatie kun je vinden op de {1} en {2}
    pagina's en mocht je vraag er niet tussen staan, stuur dan een bericht naar één van de {3}.
    We zullen deze Home pagina ook gebruiken om meer nieuws te delen de komende tijd, dus meer volgt nog!`,
  },
  danceTextFull: {
    text: `Hey! We zijn terug met een nieuwe update! We zullen het feest aftrappen met een openingsdans en
    jij kan meedoen (alleen als je wil natuurlijk)! Enthousiast? Leer de pasjes via de uitleg in deze {0},
    zodat je klaar bent om te schitteren op het podium op 7 juli. Hoe meer mensen meedoen, hoe leuker!`,
  },
  createdBy: 'Gemaakt door',
  by: 'door',
  areYouGoingFull: `Je bent uitgenodigd voor het hele programma! Het begint om 14.30. Ben je bij onze bruiloft?`,
  areYouGoingParty: `Je bent uitgenodigd voor het feest! Het begint om 20.30. Zien we je daar?`,
  yes: 'JA',
  no: 'NEE',
  ofcourse: 'Natuurlijk ben ik erbij 🤵👰',
  sadlyNo: 'Jammergenoeg kan ik er niet bij zijn 🙁',
  registerGuests: 'Geef aan welke gasten met je meekomen (inclusief jezelf)',
  guest: 'Gast',
  name: 'Naam',
  diet: 'Dieetwensen',
  everything: 'Ik eet alles',
  no_fish: 'Geen vis 🥩',
  no_meat: 'Geen vlees 🐟',
  vega: 'Vegetarisch 🧀',
  vegan: 'Veganistisch 🌱',
  allergies: 'Allergiën',
  notes: 'Overige opmerkingen (zoals deze persoon komt later)',
  savedGuests: 'Je RSVP is opgeslagen',
  addGuest: 'Voeg een gast toe',
  thanks: `Dankjewel!`,
  rsvpYesAnimation: `Jouw RSVP is verstuurd!`,
  rsvpNoAnimation: `Wat jammer!`,
  rsvpChanges: `Je kunt dit formulier later nog aanpassen`,
  sevenJuly: '7 Juli 2023',
  programLocation: `Het volledige programma vindt plaats op Orangerie Elswout. {0}
        Je hoeft dus niet van locatie te wisselen, zodat iedereen lekker kan
        relaxen en genieten!`,
  map: 'Kaart',
  programPage: {
    party: {
      title: 'Feest',
      text: `Party time! Tijd om die dansmoves te laten zien!`,
    },
    ceremony: {
      title: 'Ceremonie',
      text: `Het officiële moment! Als het weer het toe laat is dit buiten in de boomgaard. Inloop is vanaf 14:30.`,
    },
    reception: {
      title: 'Borrel',
      text: `Tijd om te proosten! Verder worden er fotos gemaakt en kun je lekker relaxen.`,
    },
    dinner: {
      title: 'Diner',
      text: 'De chefs van de Orangerie gaan wat lekkers voor ons klaarmaken. Geniet ervan!',
    },
  },
  party: 'Feest',
  save: 'Opslaan',
  submit: 'Versturen',
  networkDown:
    'De server was niet bereikbaar. Misschien doet je internet het even niet?',
  unexpectedError:
    'Er is onverwacht iets mis gegaan. Probeer het nog een keertje of herlaad de pagina.',
  wrongData:
    'Iets wat je hebt ingevuld was incorrect. Meer uitleg staat nu in het formulier.',
  faq: {
    dresscode: {
      faq: 'Wat is de dresscode?',
      answer: 'Feestelijk chique met of zonder een glitter detail.',
    },
    media: {
      faq: 'Mag ik fotos/videos maken?',
      answer: `Niet tijdens de ceremonie. We hebben een professionele fotograaf
      en videograaf om mooie opnamen van de ceremonie te maken.`,
    },
    presents: {
      faq: 'Wat voor cadeaus willen jullie?',
      answer: `We werken momenteel aan een cadeaupagina, dus vragen daarom om nog 
      een beetje meer geduld.`,
    },
    transport: {
      faq: 'Hoe kom ik bij Orangerie Elswout?',
      answer: `De locatie is het makkelijkst te bereiken met de auto. Met het 
      openbaar vervoer kun je bus 81 vanaf station Haarlem of bus 481 vanaf station
      Overveen nemen. Let op, het is nog zeker 20 minuten lopen vanaf het busstation 
      naar de Orangerie. Je kunt ook overwegen om een OV-fiets te huren bij station Haarlem.`,
    },
    parking: {
      faq: 'Waar kan ik mijn auto parkeren?',
      answer: `
        De {0} ligt op 5 minuten lopen van de Orangerie. Parkeren kost daar
        €6,50 voor een hele dag, of €1,50 per uur. Als deze parkeerplaats
        vol is, kun je in de nabijgelegen woonwijk parkeren, maar houd dan
        rekening met een wandeling van +/- 15 minuten naar de Orangerie.`,
      parkingSpot: 'dichtsbijzijnde parkeerplaats',
    },
    hotel: {
      faq: 'Waar kan ik een hotel in de buurt regelen?',
      answer: `
      Wij verblijven in {0}. De prijs voor een 2-persoonskamer varieert tussen
      €172 en €222, inclusief ontbijt. Als je daar ook blijft slapen, kun je
      de volgende ochtend gezellig met ons mee ontbijten.
      Wij zijn daar een groepsboeking aan het regelen (met een beetje korting).
      Dus stuur even een email naar {1} als je daar ook een kamer wil. Er zijn
      ook tal van andere opties in en rondom Haarlem.`,
      us: 'Shan Li of Jelte',
    },
    taxi: {
      faq: 'Zijn er taxis?',
      answer: `Taxi's moeten door jezelf geregeld worden.`,
    },
    act: {
      faq: 'Mag ik een speech houden of liedje zingen?',
      answer: 'Ja, natuurlijk! Maar overleg dat wel vantevoren met {0}.',
      ceremonyMaster: 'de ceremoniemeester',
    },
    socialmedia: {
      faq: `Mag ik mijn foto's en video's direct posten op social media?`,
      answer: `Wij zouden graag de kans krijgen om de eerste post te doen, daarom willen we je
      vragen om te wachten met posten op de dag zelf.`,
    },
    bubbles: {
      faq: `Mag ik rijst, bloemblaadjes of iets anders naar het bruidspaar 
      gooien na de ceremonie?`,
      answer: `De locatie staat alleen bellenblaas en zijden bloemblaadjes toe. 
      Het gooien van rijst, confetti of echte bloemblaadjes is niet toegestaan.`,
    },
    child: {
      faq: 'Mag ik mijn kinderen meenemen?',
      answer: `Kinderen boven de 10 zijn welkom.`,
    },
    partner: {
      faq: 'Mag ik mijn partner meenemen?',
      answer: `Ja, jouw partner, vriendin, vriend, vrouw, man, of hoe je je wederhelft ook noemt, is van harte welkom. Voeg deze persoon wel even toe als gast in de {0}.`,
      rsvp: 'RSVP',
    },
    notIncluded: {
      text: `Staat jouw vraag er niet tussen? Stuur even een berichtje naar één van de contact
      personen (zie {0}).`,
    },
  },
  presents: 'Cadeaus',
  presentsIntro: {
    text: `Mocht je op zoek zijn naar cadeau-ideeën, dan vragen we vriendelijk om {0}.😊 We zijn van plan om te gaan scuba-duiken en van 
    de zon te genieten in Azië of Zuid-Amerika (n.t.b). Aangezien we allebei enthousiaste duikers zijn, zouden we graag onze eigen duik-uitrusting willen hebben voor onze reis en 
    toekomstige vakanties. Dus als je liever een concreet cadeau wilt geven, hebben we hieronder een lijst samengesteld met duikspullen die we op prijs zouden stellen en
    andere items die op onze verlanglijst staan.`,
    contributions: 'een financiële bijdrage voor onze huwelijksreis',
  },
  buy: 'Koop',
  byYou: 'door jijzelf',
  contact: {
    mc: {
      title: `Ceremoniemeester`,
      info: `Shan Sui is de contactpersoon op de trouwdag zelf en om onderwerpen (van tevoren) te bespreken die geheim moeten blijven voor het bruidspaar.`,
    },
    bride: {
      title: `Bruid`,
      info: '',
    },
    groom: {
      title: `Bruidegom`,
      info: '',
    },
  },
};
