/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Codeword,
  CodewordRequest,
} from '../models';
import {
    CodewordFromJSON,
    CodewordToJSON,
    CodewordRequestFromJSON,
    CodewordRequestToJSON,
} from '../models';

export interface CodewordOperationRequest {
    codewordRequest: CodewordRequest;
}

/**
 * 
 */
export class CodewordApi extends runtime.BaseAPI {

    /**
     */
    async codewordRaw(requestParameters: CodewordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Codeword>> {
        if (requestParameters.codewordRequest === null || requestParameters.codewordRequest === undefined) {
            throw new runtime.RequiredError('codewordRequest','Required parameter requestParameters.codewordRequest was null or undefined when calling codeword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/codeword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodewordRequestToJSON(requestParameters.codewordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodewordFromJSON(jsonValue));
    }

    /**
     */
    async codeword(requestParameters: CodewordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Codeword> {
        const response = await this.codewordRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
