<template>
  <i18n-t keypath="faq.parking.answer" tag="p" scope="global">
    <a href="https://goo.gl/maps/iJN3zr3rGAonANoE6" target="_blank">
      {{ $t('faq.parking.parkingSpot') }}
    </a>
  </i18n-t>
</template>

<style lang="scss" scoped>
a {
  color: $faq-link;
}
</style>
