<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const openMenu = ref(false);
</script>

<template>
  <div v-if="route.name !== 'teaser'" class="navigation-bar-desktop">
    <ul class="menu-items" :class="{ open: openMenu }">
      <li><router-link to="/">Home</router-link></li>
      <li>
        <router-link to="/program">{{ $t('program') }}</router-link>
      </li>
      <li><router-link to="/rsvp">RSVP</router-link></li>
      <li><router-link to="/faq">FAQ</router-link></li>
      <li><router-link to="/presents">Presents</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
$navbarHeight: 60px;
$hamburgerThickness: 6px; //thickness line
$hamburgerThicknessHalf: math.div($hamburgerThickness, 2);
$hamburgerPadding: $space-s;
$hamburgerWidth: $navbarHeight - $hamburgerPadding * 2;
$hamburgerWidthHalf: math.div($hamburgerWidth, 2);
$diagonalScale: 1.4;
$hamburgerHeight: $hamburgerWidth;
$hamburgerSpacing: 14px; // magic number change until looks good

.navigation-bar-desktop {
  display: none;
  @include tablet-landscape {
    display: flex;
  }

  .menu-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: $font-m;
    font-weight: 300;

    @include tablet-landscape {
      font-size: $font-xm;
    }

    li {
      display: flex;
      padding: $space-xm;
      color: $golden-light;
      cursor: pointer;

      &::marker {
        content: '';
      }

      a {
        color: $golden-light;

        &:hover,
        &.router-link-exact-active {
          color: $golden-light-hover;
        }
      }

      svg {
        height: 20px;
        width: 20px;
        transform: rotate(180deg);
        margin-right: $space-xxs;
        fill: $golden-light;
      }
    }
  }
}
</style>
