<script setup lang="ts">
import WelcomeText from '@/components/WelcomeText.vue';
import WelcomeImg from '@/assets/images/img_update_welcome.jpg';
import DanceText from '@/components/DanceText.vue';
import DanceImg from '@/assets/images/img_update_dance.jpg';
import { ref, Ref } from 'vue';
import PopupFull from './PopupFull.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });

let updates = [
  {
    name: 'dance',
    component: DanceText,
    class: 'adobe',
    img: DanceImg,
    date: '15-06-2023',
  },
  {
    name: 'welcome',
    component: WelcomeText,
    class: 'adobe',
    img: WelcomeImg,
    date: '03-02-2023',
  },
];
const selectedUpdate: Ref<number | null> = ref(null);
</script>

<template>
  <div class="updates-overview">
    <h1>{{ t('latestNews') }}</h1>
    <div
      v-for="(update, i) in updates"
      :key="update.name"
      class="update"
      :class="update.class"
      @click="selectedUpdate = i"
    >
      <img class="update-img" :src="update.img" :alt="update.name + ` img`" />
      <div class="update-content">
        <div class="readmore">{{ t('readMore') }}</div>
        <h3>{{ update.date }}</h3>
        <h2 class="update-title">{{ t(`updates.${update.name}.title`) }}</h2>
        <component :is="update.component" v-if="update.component"></component>
        <p v-else>{{ t(`updates.${update.name}.text`) }}</p>
      </div>
      <div class="readmore-mobile">
        <span>{{ t('readMore') }}</span>
      </div>
    </div>
  </div>
  <PopupFull
    v-if="selectedUpdate !== null"
    :image-src="updates[selectedUpdate].img"
    @popup-closed="selectedUpdate = null"
  >
    <template #h1>
      {{ t(`updates.${updates[selectedUpdate].name}.title`) }}
    </template>
    <template #h3> {{ updates[selectedUpdate].date }}</template>
    <component
      :is="updates[selectedUpdate].component"
      v-if="updates[selectedUpdate].component"
    ></component>
    <p v-else>{{ t(`updates.${updates[selectedUpdate].name}.text`) }}</p>
  </PopupFull>
</template>

<style lang="scss" scoped>
.updates-overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: $font-xm;

  @include mobile {
    padding: 0 $space-s;
  }

  h1 {
    color: $updates-title;
    font-weight: $medium;
  }

  .update {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
    margin-bottom: $space-xm;
    background-color: $updates;
    font-size: $font-m;
    border: 30px solid $updates;
    cursor: pointer;

    @include desktop {
      flex-direction: row;
      height: 205px; // weird number, because otherwise letters are cut half
    }

    &:hover {
      background-color: $updates-hover;
      border: 30px solid $updates-hover;
    }

    h2 {
      margin: 0;
      text-align: left;
      font-weight: bold;
    }

    h3 {
      margin: 0;
      font-style: italic;
      color: #0753ab;
    }

    p {
      margin: 0;
      text-align: left;
      font-size: $font-m;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .update-content {
      position: relative;
      width: 100%;
      overflow: hidden;

      .readmore {
        position: absolute;
        right: 0;
        color: #961071;
        background-color: white;
        padding: $space-xxs $space-xs;
        border-radius: $radius10;
        display: none;

        @include desktop {
          display: flex;
        }
      }

      @include desktop {
        padding-left: $space-m;
        color: white;
      }
    }
    .readmore-mobile {
      margin: $space-s 0;
      color: #961071;

      span {
        background-color: white;
        padding: $space-xxs $space-xs;
        border-radius: $radius10;
      }

      @include desktop {
        display: none;
      }
    }
  }
}
</style>
