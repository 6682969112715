/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Codeword
 */
export interface Codeword {
    /**
     * 
     * @type {boolean}
     * @memberof Codeword
     */
    readonly fullGuest: boolean;
}

/**
 * Check if a given object implements the Codeword interface.
 */
export function instanceOfCodeword(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullGuest" in value;

    return isInstance;
}

export function CodewordFromJSON(json: any): Codeword {
    return CodewordFromJSONTyped(json, false);
}

export function CodewordFromJSONTyped(json: any, ignoreDiscriminator: boolean): Codeword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullGuest': json['full_guest'],
    };
}

export function CodewordToJSON(value?: Codeword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

