/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    password1: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    password2: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    codeword: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    name: string;
}

/**
 * Check if a given object implements the RegisterRequest interface.
 */
export function instanceOfRegisterRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password1" in value;
    isInstance = isInstance && "password2" in value;
    isInstance = isInstance && "codeword" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function RegisterRequestFromJSON(json: any): RegisterRequest {
    return RegisterRequestFromJSONTyped(json, false);
}

export function RegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'email': json['email'],
        'password1': json['password1'],
        'password2': json['password2'],
        'codeword': json['codeword'],
        'name': json['name'],
    };
}

export function RegisterRequestToJSON(value?: RegisterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email': value.email,
        'password1': value.password1,
        'password2': value.password2,
        'codeword': value.codeword,
        'name': value.name,
    };
}

