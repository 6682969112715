<template>
  <Signup>
    <template #signup-title>{{ $t('codewordTitle') }}</template>

    <form @submit="onSubmit">
      <FormErrors :errors="globalErrors" />
      <LabeledField
        name="codeword"
        :hint="t('codewordHint')"
        persistent-hint
      ></LabeledField>
      <Submit name="submit" color="normal" :loading="isSubmitting" />
    </form>
  </Signup>
</template>

<script setup lang="ts">
import { CodewordApi } from '@/client';
import Submit from '../components/Submit.vue';
import LabeledField from '@/components/LabeledField.vue';
import FormErrors from '@/components/FormErrors.vue';
import { Ref, ref } from 'vue';
import { handleFormApiErrors } from '@/helpers';
import Signup from '../components/Signup.vue';

import { useForm } from 'vee-validate';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
const store = useStore();
const { handleSubmit, isSubmitting } = useForm();
const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

const globalErrors: Ref<string[]> = ref([]);

const codewordApi = new CodewordApi();

const onSubmit = handleSubmit(async (values, actions) => {
  let response;
  try {
    response = await codewordApi.codeword({
      codewordRequest: {
        codeword: values.codeword,
      },
    });
  } catch (e) {
    await handleFormApiErrors(e, globalErrors, actions);
    return;
  }
  toast.success(t('youFoundIt'));
  globalErrors.value = [];
  const newUser = { ...store.user, fullGuest: response.fullGuest };
  store.user = newUser;
  router.push({ name: 'login' });
});
</script>
