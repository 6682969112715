<template>
  <div>{{ $t('logging in') }}</div>
</template>

<script setup lang="ts">
import { LoginApi, ResponseError } from '@/client';
import setupClient from '@/setupClient';

import type { DjangoErrorResponse } from '@/types';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

async function login() {
  const loginApi = new LoginApi();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  if (code === null) {
    toast.error(t('unexpectedError'));
    throw Error('code was undefined, url was incorrect');
  }

  try {
    await loginApi.loginGoogle({
      socialLoginRequest: {
        code: code,
      },
    });
  } catch (e) {
    if (!(e instanceof ResponseError)) {
      toast.error(t('unexpectedError'));
      throw e;
    }
    if (e.response.status == 400) {
      const errorResponse = (await e.response.json()) as DjangoErrorResponse;
      for (let error of errorResponse.errors) {
        toast.error(error.detail);
      }
      return false;
    } else {
      throw e;
    }
  }
  return true;
}

let loggedIn;
try {
  loggedIn = await login();
} catch (e) {
  router.push({ name: 'login' });
  throw e;
}

if (loggedIn) {
  toast.success(t('loggedIn'));
  setupClient();
  router.push({ name: 'home' });
} else {
  router.push({ name: 'login' });
}
</script>
