import { ResponseError } from '@/client';
import type { Ref } from 'vue';
import type { DjangoErrorResponse, VeeValidateFormActions } from '@/types';
import i18n from '@/i18n';
import { useToast } from 'vue-toastification';

export async function handleApiErrors(
  exc: unknown,
  showToast: boolean
): Promise<DjangoErrorResponse> {
  const toast = useToast();
  if (!(exc instanceof ResponseError)) {
    if (showToast) {
      toast.error(i18n.global.t('unexpectedError'));
    }
    throw exc;
  }
  if (exc.response.status == 400) {
    if (showToast) {
      toast.error(i18n.global.t('wrongData'));
    }
  }
  return await exc.response.json();
}

function toCamel(s: string) {
  return s.replace(/([-_][a-z])/gi, (match: string) => {
    return match.toUpperCase().replace('-', '').replace('_', '');
  });
}

export async function handleFormApiErrors(
  exc: unknown,
  globalErrorsRef: Ref<string[]> | null,
  actions: VeeValidateFormActions,
  showToast = true
) {
  const errorResponse = await handleApiErrors(exc, showToast);
  const errorsByField: Record<string, string[]> = errorResponse.errors.reduce(
    (hash, error) => {
      hash[error.attr] = hash[error.attr] || [];
      hash[error.attr].push(error.detail);
      return hash;
    },
    Object.create(null)
  );

  let globalErrors: string[] = [];

  for (const [field, errors] of Object.entries(errorsByField)) {
    if (field == 'non_field_errors') {
      globalErrors = errors;
      if (globalErrorsRef) {
        globalErrorsRef.value = errors;
      }
      continue;
    }
    console.log('setting errors for ' + toCamel(field), errors);
    actions.setFieldError(toCamel(field), errors);
  }
  return globalErrors;
}
