<template>
  <v-text-field
    v-model="value"
    :label="$t(props.label || props.name)"
    :error-messages="errors"
    max-errors="5"
    hide-details="auto"
    density="default"
  />
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps<{
  name: string;
  label?: string;
}>();

const { value, errors } = useField(toRef(props, 'name'));
</script>
