<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import CloseSvg from '@/assets/images/img_icon_close.svg?component';
import { Vue3Lottie } from 'vue3-lottie';
import { Json } from '@/client';

const emit = defineEmits(['popup-closed']);
const props = defineProps<{
  imageSrc?: string;
  animationSrc?: Json;
  height?: number;
  width?: number;
}>();

const htmlElem = document.querySelector('html');

onMounted(() => {
  // disable scroll on html when popup is open
  if (htmlElem) {
    htmlElem.style.overflow = 'hidden';
  }
});
onUnmounted(() => {
  // enable scroll on html when popup is closed
  if (htmlElem) {
    htmlElem.style.removeProperty('overflow');
  }
});
function close() {
  emit('popup-closed');
}
</script>

<template>
  <div class="popup-container" @click="close()">
    <div class="popup" @click.stop>
      <CloseSvg @click="close()"></CloseSvg>
      <Vue3Lottie
        v-if="props.animationSrc"
        :animation-data="props.animationSrc"
        :height="props.height"
        :width="props.width"
      ></Vue3Lottie>
      <img v-if="props.imageSrc" :src="props.imageSrc" alt="update-img" />
      <div class="popup-content">
        <h1><slot name="h1"></slot></h1>
        <h3><slot name="h3"></slot></h3>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popup-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 2;
}
.popup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 80%;
  max-height: 1000px;
  width: min(min(max(500px, 90vh), 900px), 90vw);
  background-color: white;
  position: absolute;
  overflow-y: auto;
  margin: 50px $space-xm 0;

  @include tablet-portrait {
    margin-top: 0;
  }

  svg {
    height: 80px;
    width: 80px;
    right: 0;
    top: 0;
    position: absolute;
    fill: $golden-light;
    z-index: 2;
    cursor: pointer;

    @include tablet-portrait {
      height: 100px;
      width: 100px;
    }

    &:hover {
      fill: $golden-light-hover;
    }
  }

  img {
    width: 100%;
  }

  .lottie-animation-container {
    @include tablet-portrait {
      margin: 60px 100px 0;
    }
  }

  .popup-content {
    padding: $space-m;

    h1 {
      padding: 0;
    }

    h3 {
      margin: 0 0 $space-l;
    }
  }
}
</style>
