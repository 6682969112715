<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });
</script>

<template>
  <div class="footer">
    <h2 class="creators">{{ t('createdBy') }} Jelte &amp; Shan Li</h2>
    <div class="footer-info">
      <div class="title">
        <router-link to="/privacy">Privacy Policy</router-link>
      </div>
      <div class="references">
        <p class="title">References</p>
        <p>
          Favicon {{ t('by') }}
          <a href="https://icons8.com/icons/set/wedding" target="_blank"
            >Icons8</a
          >
        </p>
        <p>
          Animations {{ t('by') }}
          <a href="https://lottiefiles.com/featured" target="_blank"
            >Lottiefiles</a
          >
        </p>
        <p>
          Background-cover {{ t('by') }}
          <a
            href="https://pngtree.com/freebackground/marble-texture-dark-blue-watercolor-smudge-background_1575976.html"
            target="_blank"
            >Pngtree</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  height: auto;
  width: 100%;
  background-image: linear-gradient(to bottom right, #8ec5fc, #d9c3fc);
  padding: $space-m 0;

  h2 {
    display: flex;
    align-items: center;
    color: white;
    text-align: center;
    padding: 0 $space-xxs;
    margin: 0;

    @include tablet-landscape {
      padding: 0 $space-l;
    }

    @include desktop {
      font-size: $font-l;
    }
  }

  .footer-info {
    display: flex;
    flex-direction: column;
    color: $footer-grey;
    padding: 0 $space-xxs;

    @include tablet-landscape {
      flex-direction: row;
      padding: 0 0;
    }

    .title {
      font-weight: bold;
    }

    div {
      text-align: left;

      @include tablet-landscape {
        padding: 0 $space-m;
      }
    }

    .references p {
      text-align: left;
      color: $footer-grey;
      min-width: 200px;
    }
  }
}
</style>
