<script setup lang="ts">
import { ref } from 'vue';
import { LogoutApi } from '@/client';

import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import imagePath from '@/assets/images/bg_blue_watercolour.png';
import ArrowSvg from '@/assets/images/img_icon_arrow.svg?component';

const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();
const route = useRoute();

const openMenu = ref(false);

async function logout() {
  const logoutApi = new LogoutApi();
  await logoutApi.logout();
  toast.success(t('logged out'));
  router.push({ name: 'login' });
}
</script>

<template>
  <div
    v-if="route.name !== 'teaser'"
    class="navigation-bar-mobile"
    :style="{
      backgroundImage: `url(${imagePath})`,
    }"
  >
    <div class="navbar-container container">
      <div
        class="hamburger-menu"
        :class="{ open: openMenu }"
        @click="openMenu = !openMenu"
      >
        <div class="icon icon-left"></div>
        <div class="icon icon-right"></div>
      </div>
      <ul
        class="menu-items"
        :class="{ open: openMenu }"
        @click="openMenu = !openMenu"
      >
        <li><router-link to="/">Home</router-link></li>
        <li>
          <router-link to="/program">{{ $t('program') }}</router-link>
        </li>
        <li><router-link to="/rsvp">RSVP</router-link></li>
        <li><router-link to="/faq">FAQ</router-link></li>
        <li><router-link to="/presents">Presents</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
        <li class="logout" @click="logout"><ArrowSvg />{{ $t('logout') }}</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
$navbarHeight: 60px;
$hamburgerThickness: 6px; //thickness line
$hamburgerThicknessHalf: math.div($hamburgerThickness, 2);
$hamburgerPadding: $space-s;
$hamburgerWidth: $navbarHeight - $hamburgerPadding * 2;
$hamburgerWidthHalf: math.div($hamburgerWidth, 2);
$diagonalScale: 1.4;
$hamburgerHeight: $hamburgerWidth;
$hamburgerSpacing: 14px; // magic number change until looks good

.navigation-bar-mobile {
  display: flex;

  @include tablet-landscape {
    display: none;
  }

  & + main .page-container {
    @include mobile {
      padding-top: $navbarHeight;
    }
  }

  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  height: $navbarHeight;
  padding: 0;
  width: 100%;
  background-color: white;
  opacity: 0.96;
  box-shadow: 0px 5px 10px 0px #aaa;
  z-index: 10;

  .navbar-container {
    color: black;
    display: block;
    height: 100%;
    width: 100%;

    .hamburger-menu {
      position: relative;
      width: $navbarHeight;
      height: $navbarHeight;
      transition-duration: 0.5s;
      cursor: pointer;

      .icon-left,
      .icon-right {
        top: math.div(($navbarHeight - $hamburgerThickness), 2);

        &,
        &:before,
        &:after {
          transition-duration: 0.5s;
          position: absolute;
          width: $hamburgerWidthHalf; // left and right width of 1 line (they are splitted in the middle)
          height: $hamburgerThickness;
          background-color: $golden;
          content: '';
        }

        &:before,
        &:after {
          content: '';
        }

        &:before {
          top: -$hamburgerSpacing;
        }

        &:after {
          top: $hamburgerSpacing;
        }
      }

      .icon-left {
        left: $hamburgerPadding;
      }
      .icon-right {
        left: $hamburgerWidthHalf + $hamburgerPadding;
      }
    }
    .hamburger-menu.open {
      .icon-left {
        transition-duration: 0.5s;
        background: transparent;

        &:before {
          transform: rotateZ(45deg) scaleX($diagonalScale)
            translate($hamburgerThicknessHalf, $hamburgerThicknessHalf);
        }

        &:after {
          transform: rotateZ(-45deg) scaleX($diagonalScale)
            translate($hamburgerThicknessHalf, -($hamburgerThicknessHalf));
        }
      }

      .icon-right {
        transition-duration: 0.5s;
        background: transparent;

        &:before {
          transform: rotateZ(-45deg) scaleX($diagonalScale)
            translate(-$hamburgerThicknessHalf, $hamburgerThicknessHalf);
        }
        &:after {
          transform: rotateZ(45deg) scaleX($diagonalScale)
            translate(-$hamburgerThicknessHalf, -$hamburgerThicknessHalf);
        }
      }
    }
  }

  .menu-items {
    margin-top: 0;
    min-width: $minScreenWidth;
    padding-top: $space-l;
    background: #fff;
    height: 100vh;
    transform: translate(-150%);
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
    order: 2;
    font-size: $font-xm;
    padding-left: 0;
    overflow-y: auto;

    &.open {
      transform: translateX(0);
      display: flex;
    }
  }

  .menu-items li {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: $space-l;
    padding-left: $space-xl;
    list-style: none;
    cursor: pointer;
    color: $golden-light;

    a {
      color: $golden-light;

      &:active,
      &.router-link-exact-active {
        color: $golden-light-hover;
        text-decoration: underline;
      }
    }

    svg {
      height: 20px;
      width: 20px;
      transform: rotate(180deg);
      margin-right: $space-xxs;
      fill: $golden-light;
    }

    &.logout {
      padding-left: $space-xxs;
    }
  }
}
</style>
