<script setup lang="ts">
import { LoginApi } from '@/client/apis/LoginApi.js';
import setupClient from '@/setupClient';
import Submit from '../components/Submit.vue';
import LabeledField from '../components/LabeledField.vue';
import GoogleLoginButton from '@/components/GoogleLoginButton.vue';
import FormErrors from '../components/FormErrors.vue';
import Cookies from 'js-cookie';
import { Ref, ref } from 'vue';
import { handleFormApiErrors } from '@/helpers';
import Signup from '../components/Signup.vue';
import { initStore } from '@/store';

import { useForm } from 'vee-validate';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const { handleSubmit, isSubmitting } = useForm();
const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

const globalErrors: Ref<string[]> = ref([]);

if (await initStore()) {
  router.push({ name: 'home' });
} else {
  Cookies.remove('sessionid');
  Cookies.remove('csrftoken');
  setupClient();
}

const loginApi = new LoginApi();

const onSubmit = handleSubmit(async (values, actions) => {
  try {
    await loginApi.login({
      loginRequest: {
        username: values.username,
        password: values.password,
      },
    });
  } catch (e) {
    await handleFormApiErrors(e, globalErrors, actions);
    return;
  }
  toast.success(t('loggedIn'));
  globalErrors.value = [];
  setupClient();
  router.push({ name: 'home' });
});

const googleLoading = ref(false);
</script>

<template>
  <Signup>
    <template #signup-title>{{ $t(`loginTo`) }} Jelte.Li</template>

    <form @submit="onSubmit">
      <FormErrors :errors="globalErrors" />
      <LabeledField name="username" label="email" />
      <LabeledField name="password" type="password" />
      <div class="password-forgot">
        <router-link to="/reset-password">{{
          $t('forgotPassword')
        }}</router-link>
      </div>
      <Submit
        name="login"
        color="normal"
        :loading="isSubmitting"
        :disabled="googleLoading"
      />
    </form>
    <span class="or">{{ $t('or') }}</span>
    <GoogleLoginButton :disabled="isSubmitting" @click="googleLoading = true" />

    <div class="go-to-register">
      {{ $t('noAccount') }}&nbsp;<router-link to="/register">
        {{ $t('createAccount') }}</router-link
      >
    </div>
  </Signup>
</template>

<style lang="scss" scoped>
form {
  opacity: 1;
}

span.or {
  display: block;
  text-align: center;
  color: white;
  font-weight: bold;
}

button.google-login,
button.google-loading-button {
  display: block;
  margin: $space-s auto;
}

button[type='submit'] {
  margin: 15px auto $space-xm;
}

.password-forgot {
  margin-top: $space-xxs;
  text-align: center;
}

.go-to-register {
  border: 1px solid white;
  border-radius: $radius6;
  min-width: 280px;
  padding: $space-s;
  margin-top: $space-m;
  text-align: center;

  a {
    font-weight: bold;
  }
}
</style>
