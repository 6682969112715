<template>
  <Signup>
    <template #signup-title>{{ $t('resetPassword') }}</template>

    <form @submit="onSubmit">
      <FormErrors :errors="globalErrors" />
      <LabeledField name="newPassword1" type="password" />
      <LabeledField name="newPassword2" type="password" />
      <Submit name="resetPassword" color="normal" :loading="isSubmitting" />
    </form>
  </Signup>
</template>

<script setup lang="ts">
import { PasswordApi } from '@/client';
import Submit from '../components/Submit.vue';
import LabeledField from '@/components/LabeledField.vue';
import FormErrors from '@/components/FormErrors.vue';
import { Ref, ref } from 'vue';
import { handleFormApiErrors } from '@/helpers';
import Signup from '../components/Signup.vue';

import { useForm } from 'vee-validate';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
const { handleSubmit, isSubmitting } = useForm();
const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();
const route = useRoute();

const globalErrors: Ref<string[]> = ref([]);

const passwordApi = new PasswordApi();
console.log(route.params);

const onSubmit = handleSubmit(async (values, actions) => {
  try {
    await passwordApi.passwordResetConfirm({
      passwordResetConfirmRequest: {
        newPassword1: values.newPassword1,
        newPassword2: values.newPassword2,
        uid: route.params.userId as string,
        token: route.params.token as string,
      },
    });
  } catch (e) {
    await handleFormApiErrors(e, globalErrors, actions);
    return;
  }
  toast.success(t('passwordResetSuccess'));
  globalErrors.value = [];
  router.push({ name: 'login' });
});
</script>
