<script setup lang="ts">
import { useStore } from '@/store';

const store = useStore();
</script>

<template>
  <p v-if="store.user.fullGuest">
    <i18n-t keypath="welcomeTextFull.text" scope="global">
      <router-link to="/rsvp">RSVP</router-link>
      <router-link to="/program">{{ $t('program') }}</router-link>
      <router-link to="/faq">FAQ</router-link>
      <router-link to="/contact">{{
        $t('welcomeTextFull.contact')
      }}</router-link
      >.
    </i18n-t>
  </p>
  <p v-else>
    <i18n-t keypath="welcomeTextParty.text" scope="global">
      <router-link to="/rsvp">RSVP</router-link>
      <router-link to="/program">{{ $t('program') }}</router-link>
      <router-link to="/faq">FAQ</router-link>
      <router-link to="/contact">{{
        $t('welcomeTextFull.contact')
      }}</router-link
      >.
    </i18n-t>
  </p>
</template>
