import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';
import Toast from 'vue-toastification';
import router from './router';
import { createVuetify, ThemeDefinition } from 'vuetify';
import { createPinia } from 'pinia';
import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';

import setupClient from './setupClient';
import i18n from './i18n';

import '@mdi/font/css/materialdesignicons.css';
import 'vue-toastification/dist/index.css';
import 'vuetify/styles';
import './assets/styles/index.scss';

setupClient();

const pinia = createPinia();
const app = createApp(App);

if (import.meta.env.PROD && import.meta.env.VITE_ENABLE_SENTRY) {
  Sentry.init({
    app,
    dsn: 'https://0be51c04e961438689e6b86b814c4a07@o4504304939827200.ingest.sentry.io/4504305435475968',
    integrations: [
      new BrowserTracing({
        // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['jelte.li', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app.use(pinia);
app.use(Vue3Lottie);
app.use(i18n);
app.use(router);
app.use(Toast, {
  transition: 'my-custom-fade',
  maxToasts: 20,
  newestOnTop: true,
});

const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#1867c0',
  },
};

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
});
app.use(vuetify);

// app.use(vuetify);

app.mount('#app');
