<template>
  <button
    v-if="!loading"
    class="google-login"
    type="button"
    tabindex="-1"
    :disabled="props.disabled"
    @click="onClick"
  />
  <v-btn
    v-else
    class="google-loading-button"
    :disabled="true"
    type="button"
    :loading="true"
  />
</template>

<script setup lang="ts">
import { LoginApi } from '@/client';
import { ref } from 'vue';

const loading = ref(false);
const loginApi = new LoginApi();

async function googleLogin() {
  loading.value = true;
  const result = await loginApi.loginGoogleUrl({ redirectRequest: {} });
  window.location.href = result.redirectTo;
}

async function onClick() {
  loading.value = true;
  try {
    if (props.clickHandler) {
      await props.clickHandler();
    } else {
      await googleLogin();
    }
  } finally {
    loading.value = false;
  }
}

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    clickHandler?: (() => unknown | Promise<unknown>) | undefined;
  }>(),
  {
    disabled: false,
    clickHandler: undefined,
  }
);
</script>

<style lang="scss" scoped>
$googleNormal1x: url(@/assets/images/btn_google_signin_light_normal_web.png);
$googleNormal2x: url(@/assets/images/btn_google_signin_light_normal_web@2x.png);
$googleFocus1x: url(@/assets/images/btn_google_signin_light_focus_web.png);
$googleFocus2x: url(@/assets/images/btn_google_signin_light_focus_web@2x.png);
$googleDisabled1x: url(@/assets/images/btn_google_signin_light_disabled_web.png);
$googleDisabled2x: url(@/assets/images/btn_google_signin_light_disabled_web@2x.png);
$googlePressed1x: url(@/assets/images/btn_google_signin_light_pressed_web.png);
$googlePressed2x: url(@/assets/images/btn_google_signin_light_pressed_web@2x.png);
.google-login,
.google-loading-button {
  height: 46px;
  width: 191px;
  margin: 2px;
}
button.google-login {
  all: unset;
  background-image: $googleNormal1x;
  height: 46px;
  width: 191px;
  cursor: pointer;
  &:focus {
    background-image: $googleNormal1x;
  }
  &:hover {
    background-image: $googleFocus1x;
  }
  &:active {
    background-image: $googlePressed1x;
  }
  &:disabled {
    background-image: $googleDisabled1x;
  }
  /* preload all button images */
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1; // hide images
    // load images
    content: $googleNormal1x $googleNormal2x $googleFocus1x $googleFocus2x
      $googleDisabled1x $googleDisabled2x $googlePressed1x $googlePressed2x;
  }
}
</style>
