/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Present
 */
export interface Present {
    /**
     * 
     * @type {number}
     * @memberof Present
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Present
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Present
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Present
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Present
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof Present
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof Present
     */
    claimedBy?: number | null;
}

/**
 * Check if a given object implements the Present interface.
 */
export function instanceOfPresent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function PresentFromJSON(json: any): Present {
    return PresentFromJSONTyped(json, false);
}

export function PresentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Present {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'url': json['url'],
        'price': json['price'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'claimedBy': !exists(json, 'claimed_by') ? undefined : json['claimed_by'],
    };
}

export function PresentToJSON(value?: Present | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'url': value.url,
        'price': value.price,
        'image': value.image,
        'claimed_by': value.claimedBy,
    };
}

