export default {
  hello: 'hello world',
  login: 'Login',
  loginTo: 'Login to',
  or: 'Or',
  loggedIn: "You're successfully logged in",
  logout: 'Logout',
  loggedOut: "You're successfully logged out",
  program: 'Program',
  username: 'Username',
  email: 'Email',
  password: 'Password',
  password1: 'Password',
  password2: 'Repeat password',
  newPassword1: 'New password',
  newPassword2: 'Repeat new password',
  codeword: 'Codeword',
  codewordHint: "It's written on your invite",
  codewordTitle: 'Enter Codeword',
  youFoundIt: 'Well done, you found it!',
  forgotPassword: 'Forgot your password?',
  resetPassword: 'Reset Password',
  getResetEmail:
    'Within a few minutes you should receive an email with a password reset link.',
  passwordResetSuccess: 'Your password has been changed. You can now login.',
  register: 'Register',
  registerFor: 'Register for',
  googleLogin: 'Login with Google',
  noAccount: 'No account?',
  createAccount: 'Create one here!',
  alreadyAccount: 'Already have an account?',
  signin: 'Sign in',
  registerSuccess: `Your email is now confirmed!`,
  registerFail: `The email confirmation link was invalid. Maybe you already used it.`,
  gettingMarried: "We're getting married!",
  moreConfetti: 'More confetti!',
  days: 'Days',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
  until: 'still to go until',
  latestNews: 'Latest News',
  readMore: 'Click to read more!',
  updates: {
    welcome: {
      title: `Welcome to Jelte.Li!`,
    },
    dance: {
      title: `Participate in our first dance!`,
    },
  },
  welcomeTextFull: {
    text: `Hooray! The time has finally come, we're getting married at Friday the 7th of July
    2023! You've been cordially invited to celebrate this special moment with us! First
    things first: please go to the {0} page to fill in the form for yourself and your guest(s).
    More information about the event can be found on the {1} and {2} pages or in case your question is
    not included, please send a message to one of the {3}. We'll also use this Home page to share
    some more updates in the coming time, so keep you posted!`,
    contact: `contact persons`,
  },
  welcomeTextParty: {
    text: `Hooray! The time has finally come, we're getting married at Friday the 7th of July
    2023! You've been cordially invited to join us at the party to celebrate this special
    moment with us! First things first: please go to the {0} page to fill in the form for
    yourself and your guest(s). More information about the event can be found on the {1}
    and {2} pages or in case your question is not included, please send a message to one
    of the {3}. We'll also use this Home page to share some more updates in the coming time,
    so keep you posted!`,
  },
  danceTextFull: {
    text: `Hi! We're back with another update! To kick of the party, we will do a first dance
    and you can participate (if you want to of course)! Excited? Learn the steps with
    this {0}, so you'll be ready to shine on stage on the 7th of July. The more people
    join, the more fun!`,
  },
  createdBy: 'Created by',
  by: 'by',
  areYouGoingFull: `You're invited to the full program! It starts at 14.30. Are you coming to our wedding?`,
  areYouGoingParty: `You're invited to the party! It starts at 20.30. Will we see you there?`,
  yes: 'YES',
  no: 'NO',
  ofcourse: "Ofcourse I'll be there 🤵👰",
  sadlyNo: 'Sadly I cannot make it 🙁',
  registerGuests:
    "Register the guests you're bringing here (including yourself)",
  guest: 'Guest',
  name: 'Name',
  diet: 'Diet',
  everything: 'I eat everything',
  no_fish: 'No fish 🥩',
  no_meat: 'No meat 🐟',
  vega: 'Vegetarian 🧀',
  vegan: 'Vegan 🌱',
  allergies: 'Allergies',
  notes: 'Other notes (e.g. this person will arrive later)',
  savedGuests: 'Your RSVP was saved successfully',
  addGuest: 'Add another guest',
  thanks: `Thanks!`,
  rsvpYesAnimation: `Your RSVP has been sent!`,
  rsvpNoAnimation: `Sorry to hear that!`,
  rsvpChanges: `It is still possible to make changes`,
  sevenJuly: '7th of July 2023',
  programLocation: `The full program will take place at the beautiful
        Orangerie Elswout, located in Overveen. {0}
        No switching locations in between, so you can fully relax and enjoy!`,
  map: 'Map',
  programPage: {
    party: {
      title: 'Party',
      text: `Party time!  Time to show off those dance moves!`,
    },
    ceremony: {
      title: 'Ceremony',
      text: `The official moment! If the Dutch weather allows it, this part will take place outside in the orchard. Walk-in is from 14:30.`,
    },
    reception: {
      title: 'Reception',
      text: `Let's celebrate with a glass of champagne! Furthermore there's time for photos, and to relax.`,
    },
    dinner: {
      title: 'Dinner',
      text: 'The chefs of the Orangerie will prepare some delicious food for us, enjoy!',
    },
  },
  save: 'Save',
  submit: 'Submit',
  networkDown: "Couldn't reach server. Is your internet working?",
  unexpectedError:
    'Something unexpected went wrong when communicating with the server. Please try again or refresh the page.',
  wrongData:
    'The data you submitted was invalid, see the errors in the form for details.',
  faq: {
    dresscode: {
      faq: 'What is the dresscode?',
      answer: 'Festive chic with or without a sparkling detail.',
    },
    media: {
      faq: 'Am I allowed to make photos/videos?',
      answer: `Not during the ceremony. We will have a professional photographer and videographer to make 
      some pretty shots for us. `,
    },
    presents: {
      faq: 'What presents do you want?',
      answer: `We're currently working on a present page, so we're asking for a little more of your patience.`,
    },
    transport: {
      faq: 'How do I get to Orangerie Elswout?',
      answer: `The location can be reached by car. With public transport you can take bus 81 from 
      Haarlem station or bus 481 from Overveen station. Please be aware that it's still a 20 minute walk 
      from the busstation to the Orangerie. You can also consider to rent an OV-bike at Haarlem station.`,
    },
    parking: {
      faq: 'Where should I park my car?',
      answer: `
        The {0} is a 5 minute walk to the Orangerie. It costs €6,50 to park all day
        or €1,50 per hour. If this spot is full, you can park in
        the residential area nearby, but then take into account a +/- 15
        minute walk to the Orangerie.`,
      parkingSpot: 'closest parking spot',
    },
    hotel: {
      faq: 'What are the hotel options?',
      answer: `
      We'll be staying at {0}. The price of a 2-person room is between
      €172 and €222, including breakfast. If you stay there too, you can join
      us the next morning at breakfast. We're organising a group booking
      there (with a small discount). So if you want a room there, please send
      an email to {1} so we can add you to that booking. There are also
      plenty of other options in and around Haarlem.
    `,
      us: 'Shan Li or Jelte',
    },
    taxi: {
      faq: 'Are there taxis?',
      answer: `Taxi's or shared rides need to be arranged by yourself.`,
    },
    act: {
      faq: 'Can I give a speech or do an act?',
      answer: 'Yes of course! But please discuss with {0}.',
      ceremonyMaster: 'the master of ceremonies',
    },
    socialmedia: {
      faq: 'Can I post my photos and videos directly on social media?',
      answer: `Since we like to have the first moment to post something, we want to ask you to wait 
      with posting things online on the day itself.`,
    },
    bubbles: {
      faq: 'Can I throw rice, flowers, etc. to the bridal couple after the ceremony?',
      answer: `The location only allows bubbles and silk flower petals. Throwing rice, 
      confetti or real flower petals is not allowed.`,
    },
    child: {
      faq: 'Can I bring my kids?',
      answer: `Kids above 10 are welcome.`,
    },
    partner: {
      faq: 'Can I bring my partner?',
      answer: `Yes, your partner, girlfriend, boyfriend, wife, husband, or whatever else you call your special someone, is very welcome. Don't forget to add this person as guest to the {0}.`,
      rsvp: 'RSVP',
    },
    notIncluded: {
      text: `Is your question not included? Please reach out to one of the contact
      persons (see {0})`,
    },
  },
  presents: 'Presents',
  presentsIntro: {
    text: `If you're looking for some gift ideas, we kindly ask for {0}.😊 We're
  planning to go scuba-diving and enjoying the sun in either Asia or South
  America (TBD). Since we're both passionate divers, we'd love to have our own
  equipment for our trip and future holidays. So if you prefer to give a more
  physical gift, we've compiled a list below of diving gear that we would
  appreciate, as well as some other items that are on our wishlist.`,
    contributions: 'financial contributions towards our honeymoon',
  },
  buy: 'Buy',
  byYou: 'by you',
  contact: {
    mc: {
      title: `Master of ceremonies`,
      info: 'Shan Sui is the main contact person on the wedding day itself and to discuss topics (in advance) that should be a secret for the bride and groom.',
    },
    bride: {
      title: `Bride`,
      info: '',
    },
    groom: {
      title: `Groom`,
      info: '',
    },
  },
};
