/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DietEnum = {
    Everything: 'everything',
    NoMeat: 'no_meat',
    NoFish: 'no_fish',
    Vega: 'vega',
    Vegan: 'vegan'
} as const;
export type DietEnum = typeof DietEnum[keyof typeof DietEnum];


export function DietEnumFromJSON(json: any): DietEnum {
    return DietEnumFromJSONTyped(json, false);
}

export function DietEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DietEnum {
    return json as DietEnum;
}

export function DietEnumToJSON(value?: DietEnum | null): any {
    return value as any;
}

