/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RegisterRequest,
  ResendEmailVerificationRequest,
  RestAuthDetail,
  Token,
  VerifyEmailRequest,
} from '../models';
import {
    RegisterRequestFromJSON,
    RegisterRequestToJSON,
    ResendEmailVerificationRequestFromJSON,
    ResendEmailVerificationRequestToJSON,
    RestAuthDetailFromJSON,
    RestAuthDetailToJSON,
    TokenFromJSON,
    TokenToJSON,
    VerifyEmailRequestFromJSON,
    VerifyEmailRequestToJSON,
} from '../models';

export interface RegistrationRequest {
    registerRequest: RegisterRequest;
}

export interface RegistrationResendEmailRequest {
    resendEmailVerificationRequest: ResendEmailVerificationRequest;
}

export interface RegistrationVerifyEmailRequest {
    verifyEmailRequest: VerifyEmailRequest;
}

/**
 * 
 */
export class RegistrationApi extends runtime.BaseAPI {

    /**
     */
    async registrationRaw(requestParameters: RegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.registerRequest === null || requestParameters.registerRequest === undefined) {
            throw new runtime.RequiredError('registerRequest','Required parameter requestParameters.registerRequest was null or undefined when calling registration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/registration/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestToJSON(requestParameters.registerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     */
    async registration(requestParameters: RegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.registrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async registrationResendEmailRaw(requestParameters: RegistrationResendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters.resendEmailVerificationRequest === null || requestParameters.resendEmailVerificationRequest === undefined) {
            throw new runtime.RequiredError('resendEmailVerificationRequest','Required parameter requestParameters.resendEmailVerificationRequest was null or undefined when calling registrationResendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/registration/resend-email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendEmailVerificationRequestToJSON(requestParameters.resendEmailVerificationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     */
    async registrationResendEmail(requestParameters: RegistrationResendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.registrationResendEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async registrationVerifyEmailRaw(requestParameters: RegistrationVerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters.verifyEmailRequest === null || requestParameters.verifyEmailRequest === undefined) {
            throw new runtime.RequiredError('verifyEmailRequest','Required parameter requestParameters.verifyEmailRequest was null or undefined when calling registrationVerifyEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/registration/verify-email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailRequestToJSON(requestParameters.verifyEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     */
    async registrationVerifyEmail(requestParameters: RegistrationVerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.registrationVerifyEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
