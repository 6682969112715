<script setup lang="ts">
import { useStore } from '@/store';
import { PresentApi } from '@/client';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { ResponseError } from '@/client';
import { ref } from 'vue';
import type { Present } from '@/client';

const props = defineProps<{
  present: Present;
}>();

const store = useStore();
const toast = useToast();
const { t } = useI18n({ useScope: 'global' });

const presentClaimedApi = new PresentApi();

const loading = ref(false);
const isClaimedByMe = ref(props.present.claimedBy === store.user.id);
const isClaimedByOther = ref(
  props.present.claimedBy !== store.user.id &&
    props.present.claimedBy !== undefined
);

const claimPresent = async () => {
  loading.value = true;
  try {
    await presentClaimedApi.presentClaim({
      id: props.present.id,
      presentClaimRequest: { claim: !isClaimedByMe.value },
    });
  } catch (exc) {
    if (!(exc instanceof ResponseError)) {
      toast.error(t('unexpectedError'));
      throw exc;
    }
    const errorResponse = await exc.response.json();
    toast.error(errorResponse.errors[0].detail);
    throw exc;
  } finally {
    loading.value = false;
  }
  isClaimedByMe.value = !isClaimedByMe.value;
  isClaimedByOther.value = false;
};
</script>

<template>
  <div class="present">
    <div
      class="claim-container"
      :class="{
        claimed: isClaimedByMe || isClaimedByOther,
      }"
    >
      <div class="claimed-header-container">
        <div v-if="isClaimedByMe || isClaimedByOther" class="claimed-header">
          CLAIMED
          <div v-if="isClaimedByMe" class="claimed-by-you">
            {{ $t('byYou') }}
          </div>
        </div>
      </div>
      <div class="present-details">
        <div
          class="present-cover"
          :style="{ 'background-image': `url(${props.present.image})` }"
          :alt="props.present.name + ` cover photo`"
        ></div>
        <h3 class="title">{{ `${props.present.name}` }}</h3>
        <p class="description">{{ `${props.present.description}` }}</p>
        <p class="price">{{ `€${props.present.price}` }}</p>
      </div>
    </div>
    <div
      class="buttons"
      :class="{
        disabled: isClaimedByOther || loading,
      }"
    >
      <div
        class="claim"
        :class="{ unclaim: isClaimedByMe }"
        @click="claimPresent()"
      >
        {{ isClaimedByMe ? 'Unclaim' : 'Claim' }}
      </div>
      <a :href="props.present.url" target="_blank">
        {{ $t('buy') }}
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.present {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 300px;
  padding: $space-xs;
  background-color: white;
  line-height: 1.2;

  .claim-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    position: relative;

    &.claimed .present-details {
      opacity: 0.3;
    }

    .claimed-header-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .claimed-header {
        background-color: white;
        color: darkgoldenrod;
        font-size: 40px;
        position: absolute;
        opacity: 1;
        top: 16%;
        padding: 0px 19px;
        transform: rotate(-36deg);
        z-index: 3;
        .claimed-by-you {
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .present-cover {
      display: block;
      margin-left: auto;
      margin-right: auto;
      background-size: contain;
      background-position: center;
      max-width: unset;
      width: 150px;
      height: 150px;
    }

    .title {
      text-align: left;
      font-weight: bold;
      width: 100%;
      margin-bottom: $space-xs;

      @include mobile {
        font-size: 18px;
      }
    }

    p {
      text-align: left;
      width: 100%;

      &.price {
        font-weight: bold;
        margin: $space-xxs 0 $space-s;
      }

      @include mobile {
        font-size: 18px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    font-size: $font-m;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .claim {
    width: 86px;
    padding: $space-xxs 0;
    background-color: $body-color;
    color: white;
    text-align: center;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        opacity: 0.3;
      }
    }

    &.unclaim {
      background-color: $error-color;
    }
  }

  a {
    flex-grow: 1;
    text-align: center;
    border: 1px solid $golden;
    max-width: 200px;
    color: $golden;
    padding: $space-xxs 0;
    margin-left: $space-xs;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
        background-color: $golden;
        color: white;
      }
    }
  }
}
</style>
