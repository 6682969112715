<script setup lang="ts">
import CeremonyCover from '@/assets/images/img_program_ceremony.jpeg';
import ReceptionCover from '@/assets/images/img_program_reception.jpeg';
import DinnerCover from '@/assets/images/img_program_dinner.jpeg';
import PartyCover from '@/assets/images/img_program_party.jpeg';
import CeremonySvg from '@/assets/images/img_icon_ceremony.svg?component';
import ReceptionSvg from '@/assets/images/img_icon_reception.svg?component';
import DinnerSvg from '@/assets/images/img_icon_dinner.svg?component';
import PartySvg from '@/assets/images/img_icon_party.svg?component';
import PageTitle from '@/components/PageTitle.vue';
import { useStore } from '@/store';

const store = useStore();

let program = [
  {
    name: 'party',
    time: '20:30–01:00',
    icon: PartySvg,
    img: PartyCover,
  },
];
if (store.user.fullGuest) {
  program = [
    {
      name: 'ceremony',
      time: `15:00`,
      icon: CeremonySvg,
      img: CeremonyCover,
    },
    {
      name: 'reception',
      time: '16:00',
      icon: ReceptionSvg,
      img: ReceptionCover,
    },
    {
      name: 'dinner',
      time: '18:00',
      icon: DinnerSvg,
      img: DinnerCover,
    },
    ...program,
  ];
}
</script>

<template>
  <PageTitle>{{ $t('program') }}</PageTitle>
  <div class="program-container">
    <div class="location">
      <img
        class="location-cover"
        src="@/assets/images/img_orangerie_3.jpeg"
        alt="orangerie elswout photo"
      />
      <p class="date">
        <em>~ {{ $t('sevenJuly') }} ~</em>
      </p>
      <h1>Orangerie Elswout</h1>
      <i18n-t keypath="programLocation" tag="p" scope="global">
        <br />
      </i18n-t>
      <p class="adress">
        Elswoutslaan 22 <br />
        2051 AE Overveen
      </p>
      <a
        class="map"
        title="Open in Google Maps"
        href="https://goo.gl/maps/V5hYiF8mDcgCHJgr6"
        target="_blank"
        >{{ $t('map') }}</a
      >
    </div>
    <div class="program">
      <div v-for="(item, index) in program" :key="index" class="program-item">
        <div class="content-left">
          <div
            class="program-cover"
            :style="{ 'background-image': `url(${item.img})` }"
            :alt="item.name + ` cover photo`"
          />
        </div>
        <div class="content-right">
          <div class="program-item-content">
            <component
              :is="item.icon"
              class="icon"
              :alt="item.name + `icon`"
            ></component>
            <h1>{{ $t(`programPage.${item.name}.title`) }}</h1>
            <h2>{{ item.time }}</h2>
            <p>{{ $t(`programPage.${item.name}.text`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$icon-size: 60px;
.location {
  margin-bottom: 80px;

  h1 {
    text-align: center;
  }
  p {
    font-size: $font-m;
  }

  .date {
    margin-top: $space-s;
    color: $golden;
    font-size: $font-xm;
  }
  .adress {
    padding: $space-xm 0;
    font-style: italic;
  }

  .map {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: 1px solid $golden;
    width: 125px;
    color: $golden;
    font-size: $font-m;
    padding: $space-xxs $space-m;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background-color: $golden;
      color: white;
    }
  }
}
.program-item {
  display: flex;
  flex-direction: column;
  padding: $space-s;
  margin-bottom: $space-l;

  .program-cover {
    background-size: cover;
    background-position: center;
    max-width: unset;
  }

  .program-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    p {
      font-size: $font-m;
    }

    .icon {
      height: $icon-size;
      width: $icon-size;
      fill: $golden;
    }
  }
}

@include tablet-landscape {
  .program-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    padding: $space-s;
    margin-bottom: $space-l;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: $space-xl;
      bottom: 0;
      right: 0;
      left: $space-xl;
      border: 1px solid $golden;
      z-index: 12;
    }

    .content-right,
    .content-left {
      width: 48%;
    }

    .content-left {
      opacity: 1;
      transform: translateX(0);
    }

    .content-right {
      padding-top: 60px;
      z-index: 15;
      height: 400px;
    }

    .program-cover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: cover;
      background-position: center;
      max-width: unset;
    }
  }
}

@media (max-width: 899px) {
  h1 {
    font-size: $font-l;
  }

  h2 {
    margin: $space-s 0;
  }
  .program-cover {
    padding-top: 100%;
    margin: 0 auto $space-m;
  }

  p {
    padding: $space-s;
  }

  .location {
    margin-bottom: $space-l;
  }
}
</style>
