<script setup lang="ts">
// import { ref } from 'vue';
import imagePath from '@/assets/images/bg_blue_watercolour-mirrored-twice.jpg';
import NavigationBar from './NavigationBar.vue';
import { initStore } from '@/store';
import Footer from '@/components/Footer.vue';
// import PopupFull from './PopupFull.vue';
import WebsiteTitle from './WebsiteTitle.vue';

const loggedIn = await initStore();
</script>

<template>
  <div
    v-if="loggedIn"
    class="page-body"
    :style="{
      backgroundImage: `url(${imagePath})`,
    }"
  >
    <div class="page-container">
      <WebsiteTitle></WebsiteTitle>
      <NavigationBar />
      <div class="page-content-divider"></div>
      <div class="page-content">
        <slot></slot>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-body {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-size: auto;
  background-repeat: repeat;
  justify-content: center;
  background-color: $bg-color;

  .page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    box-shadow: 0px 0 15px 0px gray;
    color: black;
    max-width: 1600px;

    @include tablet-portrait {
      width: 80%;
    }

    .page-content-divider {
      height: 1px;
      background-color: $golden-light;
      width: 75%;

      @include mobile {
        width: 85%;
        margin-top: $space-m;
      }
    }

    .page-content {
      width: 100%;
      margin-bottom: calc(2 * $footerHeight);

      @include desktop {
        margin-bottom: calc(2 * $footerHeightDesktop);
      }

      @include tablet-portrait {
        width: 75%;
      }
    }
  }
}
</style>
