<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });

const weddingDate = '2023-07-07T07:00:00',
  secondMultiplier = 1000,
  minuteMultiplier = secondMultiplier * 60,
  hourMultiplier = minuteMultiplier * 60,
  dayMultiplier = hourMultiplier * 24;

let seconds = ref(0),
  minutes = ref(0),
  hours = ref(0),
  days = ref(0);

const times = [
  { id: 0, text: 'days', time: days },
  { id: 1, text: 'hours', time: hours },
  { id: 2, text: 'minutes', time: minutes },
  { id: 3, text: 'seconds', time: seconds },
];

const countDown = new Date(weddingDate).getTime();

function updateCountdown() {
  const now = new Date().getTime(),
    distance = countDown - now;

  days.value = Math.max(0, Math.floor(distance / dayMultiplier));
  hours.value = Math.max(
    0,
    Math.floor((distance % dayMultiplier) / hourMultiplier)
  );
  minutes.value = Math.max(
    0,
    Math.floor((distance % hourMultiplier) / minuteMultiplier)
  );
  seconds.value = Math.max(
    0,
    Math.floor((distance % minuteMultiplier) / secondMultiplier)
  );
}

updateCountdown(); /* Initialize the countdown */

let countdownInterval: ReturnType<typeof setInterval>;

onMounted(() => {
  // Only activate countdown when the component is visible on active page
  countdownInterval = setInterval(updateCountdown, 1000);
});
onUnmounted(() => {
  // If not on homepage, clear interval
  clearInterval(countdownInterval);
});
</script>

<template>
  <div class="countdown-container">
    <div class="countdown">
      <div v-for="time in times" :key="time.id" class="countdown-item">
        <span>{{ time.time }}</span>
        {{ t(time.text) }}
      </div>
    </div>
    <h2>{{ t('until') }}</h2>
    <h2 class="wedding-date">✨ 07-07-2023 ✨</h2>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .countdown {
    display: flex;
    flex-direction: row;
    background-color: $bg-color;
    padding: $space-s;
    border-radius: $radius5;
    background-image: linear-gradient(
      to bottom right,
      #76afdb,
      #6db1d5,
      #69b2cd,
      #69b3c4,
      #6cb3bb,
      #6db5be,
      #6eb7c0,
      #6fb9c3,
      #6fbdd3,
      #75c1e2,
      #82c4f0,
      #94c5fc
    );

    @include tablet-portrait {
      padding: $space-xm;
    }
    .countdown-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.5);
      padding: $space-s;
      width: 75px;
      margin: 0 $space-xxs;
      font-size: $font-xs;
      border-radius: $radius5;
      color: #531561;
      position: sticky;
      bottom: 0;

      span {
        font-size: $font-xm;
        font-weight: bold;
      }

      @include tablet-portrait {
        width: 80px;
        margin: 0 $space-s;
        font-size: $font-s;

        span {
          font-size: $font-l;
        }
      }
    }
  }
  h2 {
    color: $golden;
    margin: $space-xxs 0 0;
    font-size: 18px;

    &.wedding-date {
      font-size: 30px;
    }
  }
}
</style>
