/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Guest } from './Guest';
import {
    GuestFromJSON,
    GuestFromJSONTyped,
    GuestToJSON,
} from './Guest';

/**
 * 
 * @export
 * @interface GuestBulk
 */
export interface GuestBulk {
    /**
     * 
     * @type {boolean}
     * @memberof GuestBulk
     */
    acceptedInvite: boolean;
    /**
     * 
     * @type {Array<Guest>}
     * @memberof GuestBulk
     */
    guests: Array<Guest>;
}

/**
 * Check if a given object implements the GuestBulk interface.
 */
export function instanceOfGuestBulk(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "acceptedInvite" in value;
    isInstance = isInstance && "guests" in value;

    return isInstance;
}

export function GuestBulkFromJSON(json: any): GuestBulk {
    return GuestBulkFromJSONTyped(json, false);
}

export function GuestBulkFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestBulk {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptedInvite': json['accepted_invite'],
        'guests': ((json['guests'] as Array<any>).map(GuestFromJSON)),
    };
}

export function GuestBulkToJSON(value?: GuestBulk | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accepted_invite': value.acceptedInvite,
        'guests': ((value.guests as Array<any>).map(GuestToJSON)),
    };
}

