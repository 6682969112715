<script setup lang="ts">
import { GuestApi, DietEnum, GuestBulkRequest } from '@/client';
import { Ref, ref } from 'vue';

import Submit from '@/components/Submit.vue';
import LabeledField from '@/components/LabeledField.vue';
import FormErrors from '@/components/FormErrors.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import Radio from '@/components/Radio.vue';
import { handleFormApiErrors } from '@/helpers';
import PageTitle from '@/components/PageTitle.vue';
import PopupFull from '@/components/PopupFull.vue';
import CheckmarkJSON from '@/assets/images/animation_confetti_checkmark.json';
import SadKittenJSON from '@/assets/images/animation_sad_kitten.json';
import SadEmojiJSON from '@/assets/images/animation_sad_emoji.json';

import { useForm, useFieldArray } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
const { t } = useI18n({ useScope: 'global' });
const store = useStore();

const guestApi = new GuestApi();
let initialGuests = await guestApi.guestList();
const isAppleBrowser =
  navigator.vendor && navigator.vendor.indexOf('Apple') > -1;
const sadJSON = isAppleBrowser ? SadEmojiJSON : SadKittenJSON;

function defaultGuest() {
  let name;
  if (store.user.lastName) {
    name = `${store.user.firstName} ${store.user.lastName}`;
  } else {
    name = store.user.firstName || '';
  }
  return {
    name,
    diet: null as unknown as DietEnum,
  };
}
if (initialGuests.length == 0) {
  initialGuests = [defaultGuest()];
}

const { handleSubmit, isSubmitting, values, meta } = useForm({
  initialValues: {
    acceptedInvite: store.user.acceptedInvite,
    guests: initialGuests,
  },
});

const { remove, push, fields } = useFieldArray('guests');
const form = ref();
let showPopup = ref(false);

const globalErrors: Ref<string[]> = ref([]);
const onSubmit = handleSubmit(async (submittedValues, actions) => {
  await new Promise((resolve) => setTimeout(resolve, 200));
  if (!submittedValues.acceptedInvite) {
    submittedValues.guests = [];
  }
  try {
    await guestApi.guestUpdate({
      guestBulkRequest: submittedValues as GuestBulkRequest,
    });
  } catch (e) {
    await handleFormApiErrors(e, globalErrors, actions);
    throw e;
  }
  if (!submittedValues.acceptedInvite) {
    submittedValues.guests = [defaultGuest()];
  }
  store.user.acceptedInvite = submittedValues.acceptedInvite;
  actions.resetForm({ values: submittedValues });
  showPopup.value = true;

  // set timeout on pop-up to close after 4 seconds
  setTimeout(() => {
    showPopup.value = false;
  }, 4000);
});
</script>

<template>
  <PageTitle>RSVP</PageTitle>
  <div class="rsvp-container">
    <h2 v-if="store.user.fullGuest">{{ $t('areYouGoingFull') }}</h2>
    <h2 v-else>{{ $t('areYouGoingParty') }}</h2>
    <form ref="form" @submit="onSubmit">
      <FormErrors :errors="globalErrors" />
      <RadioGroup class="yes-no" name="acceptedInvite" nolabel>
        <Radio class="yes" label="ofcourse" :value="true">
          <span>{{ t('yes') }}</span>
        </Radio>
        <Radio class="no" label="sadlyNo" :value="false">
          <span>{{ t('no') }}</span>
        </Radio>
      </RadioGroup>
      <div :class="{ hidden: !values.acceptedInvite }">
        <h3>{{ $t('registerGuests') }}</h3>
        <div v-for="(field, index) in fields" :key="index" class="guest">
          <p>
            {{
              values.guests[index]?.name
                ? values.guests[index]?.name
                : `${$t('guest')} ${index + 1}`
            }}
            <v-btn
              v-if="index > 0"
              icon="mdi-close-thick"
              color="error"
              size="x-small"
              @click="remove(index)"
            />
          </p>
          <LabeledField :name="`guests.${index}.name`" label="name" />
          <RadioGroup :name="`guests.${index}.diet`" label="diet" inline>
            <Radio value="everything" />
            <Radio value="no_fish" />
            <Radio value="no_meat" />
            <Radio value="vega" />
            <Radio value="vegan" />
          </RadioGroup>
          <LabeledField :name="`guests.${index}.allergies`" label="allergies" />
          <LabeledField :name="`guests.${index}.notes`" label="notes" />
        </div>
      </div>

      <div class="footer-buttons">
        <v-btn
          v-if="values.acceptedInvite"
          color="success"
          class="add-button"
          @click="push({ name: '' })"
        >
          {{ $t('addGuest') }}
        </v-btn>

        <div class="submit-wrapper">
          <Submit
            :disabled="!meta.dirty || values.acceptedInvite == null"
            :loading="isSubmitting"
          />
        </div>
      </div>
    </form>
    <PopupFull
      v-if="showPopup"
      :animation-src="values.acceptedInvite ? CheckmarkJSON : sadJSON"
      :height="300"
      :width="300"
      @popup-closed="showPopup = false"
    >
      <template v-if="values.acceptedInvite" #h1
        >{{ $t('thanks') }} <br />{{ $t('rsvpYesAnimation') }}</template
      >
      <template v-else #h1>{{ $t('rsvpNoAnimation') }}</template>
      <h3>{{ $t('rsvpChanges') }}</h3>
    </PopupFull>
  </div>
</template>

<style lang="scss" scoped>
.rsvp-container {
  @include mobile {
    padding: 0 $space-m;
  }

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    margin-top: 0;
    padding: $space-m;
    border: 2px solid $golden-light;
    border-radius: $radius10;
    box-shadow: 1px 1px 5px 0px grey;
    background-color: $white-yellow;
  }

  h3 {
    margin: 0 0;
    font-weight: $semi-bold;
  }

  h4 {
    margin: 0;
    margin-bottom: $space-l;
    font-weight: $regular;
  }

  p {
    text-align: left;
    font-weight: bold;
  }

  .hidden {
    display: none;
  }

  .guest {
    margin-bottom: $space-s;
  }

  form {
    position: relative;

    p button {
      margin-left: $space-xxs;
    }

    :deep(.v-input) {
      i {
        color: $body-color;
      }
    }

    // Restyle radio-buttons of first rsvp form question
    :deep(.v-input.yes-no) {
      .v-selection-control-group {
        flex-direction: row;
        margin-bottom: $space-l;

        span {
          position: absolute;
          color: $body-color;
        }

        i {
          &:before {
            content: '';
          }
        }

        .v-label {
          text-align: center;
        }
        .yes {
          flex-direction: row-reverse;

          label {
            justify-content: flex-end;
            padding-right: $space-xm;
          }
          .v-selection-control__input {
            border: 1px solid $golden-light;
            border-radius: 5px 0px 0px 5px;
          }
        }
        .no {
          label {
            padding-left: $space-xm;
          }
          .v-selection-control__input {
            border: 1px solid $golden-light;
            border-radius: 0px 5px 5px 0px;
          }
        }

        .v-selection-control--dirty {
          span,
          .v-label {
            font-weight: bold;
            color: $golden-light;
          }
        }
      }
    }
  }

  .footer-buttons {
    display: flex;
    align-items: center;

    .submit-wrapper {
      display: flex;
      flex-basis: 100%;
      margin-left: $space-xm;

      :deep(.submit) {
        margin: 0;
        display: flex;
        width: 120px;
      }
    }
  }

  :deep(.popup) {
    justify-content: center;
  }
}
</style>
