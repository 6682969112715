<template>
  <h1 class="page-title">
    <slot></slot>
  </h1>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.page-title {
  text-align: center;
  color: $golden-light;
  padding: $space-l 0;
  font-size: $font-xl;

  @include mobile {
    font-size: $font-l;
  }
}
</style>
