<template>
  <i18n-t keypath="faq.partner.answer" tag="p" scope="global">
    <router-link to="/rsvp">{{ $t('faq.partner.rsvp') }}</router-link>
  </i18n-t>
</template>

<style lang="scss" scoped>
a {
  color: $faq-link;
}
</style>
