<script setup lang="ts">
import { ref, watch } from 'vue';
import Countdown from '@/components/Countdown.vue';
// import CountdownTest from '@/components/Countdown_test.vue';

import PageTitle from '@/components/PageTitle.vue';
import Updates from '@/components/Updates.vue';
import { useI18n } from 'vue-i18n';

// import gsap from 'gsap';
import confetti from 'canvas-confetti';
const { t } = useI18n({ useScope: 'global' });

interface ConfettiOptions {
  particleCount?: number;
  spread?: number;
  origin?: {
    x?: number;
    y?: number;
  };
  colors?: string[];
  disableForReducedMotion?: boolean;
}

// Get the current time
const now: Date = new Date();

// Set the target time to 23:30
const targetTime: Date = new Date(2023, 6, 7, 7, 0, 0);

// Calculate the time difference between now and the target time
const timeDifference = ref<number>(targetTime.getTime() - now.getTime());

// Start the countdown
const countdownInterval = setInterval(() => {
  timeDifference.value = targetTime.getTime() - new Date().getTime();
}, 1000);

// Watch for changes in the time difference
watch(timeDifference, (newValue) => {
  if (newValue <= 0) {
    clearInterval(countdownInterval);
    celebrate();

    // Set an interval to call the celebrate function every 15 seconds
    setInterval(() => {
      celebrate();
    }, 5000);
  }
});

const isCelebrate = ref(false);
const confettiFunction: (options?: ConfettiOptions) => void = confetti;

const celebrate = () => {
  isCelebrate.value = true;
  confettiFunction({
    particleCount: 150,
    spread: 100,
    origin: { y: 0.6 },
    colors: ['#b8860b', '#588bc5', '#9597f4'],
    disableForReducedMotion: true,
  });
};
</script>

<template>
  <PageTitle>Home</PageTitle>
  <div class="home-cover-container">
    <img
      class="home-cover"
      src="@/assets/images/img_cover_palace_selfie.jpg"
      alt="Jelte &amp; Shan Li at Monserrate palace in Sintra "
    />
    <h2 class="getting-married">{{ t('gettingMarried') }}</h2>
  </div>
  <Countdown :class="{ celebrate: isCelebrate }">
    <div v-if="isCelebrate" class="more-confetti-button" @click="celebrate()">
      {{ t('moreConfetti') }}
    </div>
  </Countdown>
  <Updates></Updates>
</template>

<style lang="scss" scoped>
.home-cover-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
  overflow-x: hidden;

  img.home-cover {
    align-self: flex-start;
  }

  h2.getting-married {
    position: absolute;
    color: white;
    width: 200px;
    text-align: center;
    right: 0;
    top: 3%;
    transform: rotate(16deg);
    text-shadow: 1px 1px #b6afafbd;
    line-height: 1;

    @include tablet-portrait {
      width: 200px;
      font-size: 40px;
    }
    @include tablet-landscape {
      width: 300px;
      font-size: 60px;
    }
    @include desktop {
      width: 400px;
      font-size: 80px;
    }
    @include big-desktop {
      width: 500px;
      font-size: 100px;
    }
  }
}

.more-confetti-button {
  border: 1px solid #531561;
  padding: $space-xxs $space-xs;
  border-radius: $radius10;
  font-size: $font-m;
  margin-top: $space-xm;
  background-color: #531561;
  color: white;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
</style>
