<template>
  <h1 class="website-title"><span>Jelte &amp; Shan Li</span></h1>
</template>

<style lang="scss" scoped>
.website-title {
  width: 100%;
  color: transparant;
  text-align: center;
  padding-top: 100px;

  background-image: repeating-linear-gradient(
    to right,
    #e9bb75 0%,
    #b8860b 5%,
    #b8860b 22%,
    #b8860b 45%,
    #e9bb75 50%,
    #b8860b 55%,
    #b8860b 78%,
    #b8860b 95%,
    #e9bb75 100%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  background-position-x: 0;
  background-position-y: 0;
  background-size: 200%;
  background-repeat: repeat-x;
  animation: gradient 15s linear infinite;

  @keyframes gradient {
    0% {
      background-position-x: 80%;
    }
    32%,
    92% {
      background-position-x: 0%;
    }
    100% {
      background-position-x: -20%;
    }
  }

  @include mobile {
    padding-top: $space-s;
  }
}
</style>
