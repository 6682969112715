import LoginView from '../views/LoginView.vue';
import GoogleLoginView from '../views/GoogleLoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';
import ResetPasswordConfirmView from '../views/ResetPasswordConfirmView.vue';
import CodewordView from '../views/CodewordView.vue';
import HomeView from '../views/HomeView.vue';
import ProgramView from '../views/ProgramView.vue';
import RsvpView from '../views/RsvpView.vue';
import FAQView from '../views/FAQView.vue';
import PresentsView from '../views/PresentsView.vue';
import ContactView from '../views/ContactView.vue';
import ConfirmEmailView from '../views/ConfirmEmailView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';
import NotFoundView from '../views/NotFoundView.vue';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/register',
      name: 'register',
      meta: {
        title: 'Register',
      },
      component: RegisterView,
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      meta: {
        title: 'Confirm Email',
      },
      component: ConfirmEmailView,
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        title: 'Login',
      },
      component: LoginView,
    },
    {
      path: '/login/google',
      name: 'google-login',
      meta: {
        title: 'Login',
      },
      component: GoogleLoginView,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: {
        title: 'Reset password',
      },
      component: ResetPasswordView,
    },
    {
      path: '/codeword',
      name: 'codeword',
      meta: {
        title: 'Codeword check',
      },
      component: CodewordView,
    },
    {
      path: '/reset-password/confirm/:userId/:token/',
      name: 'reset-password-confirm',
      meta: {
        title: 'Reset password',
      },
      component: ResetPasswordConfirmView,
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/program',
      name: 'program',
      meta: {
        title: 'Program',
      },
      component: ProgramView,
    },
    {
      path: '/rsvp',
      name: 'rsvp',
      meta: {
        title: 'RSVP form',
      },
      component: RsvpView,
    },
    {
      path: '/faq',
      name: 'faq',
      meta: {
        title: 'FAQ',
      },
      component: FAQView,
    },
    {
      path: '/presents',
      name: 'presents',
      meta: {
        title: 'Presents',
      },
      component: PresentsView,
    },
    {
      path: '/contact',
      name: 'contact',
      meta: {
        title: 'Contact info',
      },
      component: ContactView,
    },
    {
      path: '/privacy',
      name: 'privacy',
      meta: {
        title: 'Privacy Policy',
      },
      component: PrivacyPolicyView,
    },
    // IMPORTANT: this should always be the last route!
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      meta: {
        title: 'Page not found',
      },
      component: NotFoundView,
    },
  ],
});

router.afterEach((to) => {
  const defaultTitle = 'Jelte en Li trouwwebsite';
  const customTitle = to.meta.title as string | undefined;
  document.title = customTitle
    ? `${customTitle} | ${defaultTitle}`
    : defaultTitle;
});

export default router;
