/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Guest,
  GuestBulk,
  GuestBulkRequest,
} from '../models';
import {
    GuestFromJSON,
    GuestToJSON,
    GuestBulkFromJSON,
    GuestBulkToJSON,
    GuestBulkRequestFromJSON,
    GuestBulkRequestToJSON,
} from '../models';

export interface GuestUpdateRequest {
    guestBulkRequest: GuestBulkRequest;
}

/**
 * 
 */
export class GuestApi extends runtime.BaseAPI {

    /**
     */
    async guestListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Guest>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/guests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GuestFromJSON));
    }

    /**
     */
    async guestList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Guest>> {
        const response = await this.guestListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async guestUpdateRaw(requestParameters: GuestUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestBulk>> {
        if (requestParameters.guestBulkRequest === null || requestParameters.guestBulkRequest === undefined) {
            throw new runtime.RequiredError('guestBulkRequest','Required parameter requestParameters.guestBulkRequest was null or undefined when calling guestUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/guests`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GuestBulkRequestToJSON(requestParameters.guestBulkRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestBulkFromJSON(jsonValue));
    }

    /**
     */
    async guestUpdate(requestParameters: GuestUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestBulk> {
        const response = await this.guestUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
