import { DefaultConfig, Configuration, HTTPHeaders } from '@/client';
import { apiHost } from '@/config';
import { useToast } from 'vue-toastification';
import i18n from '@/i18n';
import router from '@/router';
import Cookies from 'js-cookie';

export default function setupClient(locale?: string) {
  const csrfToken = Cookies.get('csrftoken');

  const headers: HTTPHeaders = {};
  if (csrfToken !== undefined) {
    headers['X-CSRFToken'] = csrfToken;
  }
  const localeRef = i18n.global.locale as unknown as { value: string };
  headers['Accept-Language'] = locale || localeRef.value;

  DefaultConfig.config = new Configuration({
    basePath: apiHost,
    credentials: 'include',
    headers: headers,
    middleware: [
      {
        onError: async ({ error }) => {
          if (error instanceof TypeError) {
            const toast = useToast();
            if (import.meta.env.DEV) {
              toast.error(
                "Couldn't reach development API server. You probably forgot to turn it on."
              );
            } else {
              toast.error(i18n.global.t('networkDown'));
            }
          }
        },
        post: async ({ response }) => {
          if (response.status == 403) {
            router.push({ name: 'login' });
          }
          if (response.status == 500) {
            const toast = useToast();
            toast.error(i18n.global.t('unexpectedError'));
          }
        },
      },
    ],
  });
}
