<script setup lang="ts">
import { ref, watch } from 'vue';
import PageTitle from '@/components/PageTitle.vue';
import Present from '@/components/Present.vue';

import { PresentApi } from '@/client';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n({ useScope: 'global' });
const presentApi = new PresentApi();
const presents = ref(await presentApi.presentList());
watch(locale, async () => {
  presents.value = await presentApi.presentList();
});
</script>

<template>
  <PageTitle>{{ $t('presents') }}</PageTitle>
  <div class="presents-intro">
    <i18n-t keypath="presentsIntro.text" scope="global">
      <strong>{{ $t('presentsIntro.contributions') }}</strong>
    </i18n-t>
  </div>
  <div class="presents-container">
    <div class="presents">
      <Present v-for="item in presents" :key="item.id" :present="item" />
    </div>
  </div>
</template>

//
<style lang="scss" scoped>
.presents-intro {
  font-size: $font-m;
  margin-bottom: $space-xl;
  margin-right: $space-m;
  margin-left: $space-m;
  min-height: 100px;
  margin-top: 0;
  padding: $space-m;
  border: 2px solid $golden-light;
  border-radius: $radius10;
  box-shadow: 1px 1px 5px 0px grey;
  background-color: $white-yellow;

  @include mobile {
    margin-right: $space-xs;
    margin-left: $space-xs;
  }

  @include tablet-portrait {
    margin-right: 0;
    margin-left: 0;
  }
}

.presents {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row; /* wrap the items to the next row */
  gap: $space-xm 1.5%;

  @include tablet-landscape {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row; /* wrap the items to the next row */
  }

  @include mobile {
    margin: 0 $space-xxs;
  }
}
</style>
