import { createI18n } from 'vue-i18n';
import en from './locales/en.js';
import nl from './locales/nl.js';

type MessageSchema = typeof en;
const locale =
  localStorage.getItem('locale') || navigator.language.split('-')[0];
export default createI18n<[MessageSchema], 'en' | 'nl'>({
  legacy: false,
  globalInjection: true,
  locale,
  fallbackLocale: 'en',
  // something vue-i18n options here ...
  messages: {
    en: en,
    nl: nl,
  },
});
