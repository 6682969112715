<template>
  <v-radio-group
    v-model="value"
    v-bind="$attrs"
    :label="props.nolabel ? undefined : $t(props.label || props.name)"
    :error-messages="errors"
    hide-details="auto"
    density="default"
  >
    <slot />
  </v-radio-group>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps<{
  name: string;
  label?: string;
  nolabel?: boolean;
}>();

const { value, errors } = useField(toRef(props, 'name'));
</script>
