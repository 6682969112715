<script setup lang="ts">
import { RegistrationApi, RegisterRequest, LoginApi } from '@/client';
import setupClient from '@/setupClient';

import Submit from '../components/Submit.vue';
import LabeledField from '../components/LabeledField.vue';
import GoogleLoginButton from '@/components/GoogleLoginButton.vue';
import FormErrors from '../components/FormErrors.vue';
import { Ref, ref } from 'vue';
import { handleFormApiErrors } from '@/helpers';
import Signup from '../components/Signup.vue';

import { useForm } from 'vee-validate';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const toast = useToast();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

const registrationApi = new RegistrationApi();

const globalErrors: Ref<string[]> = ref([]);

const urlParams = new URLSearchParams(window.location.search);
const { handleSubmit, isSubmitting } = useForm({
  initialValues: {
    codeword: urlParams.get('codeword'),
  } as RegisterRequest,
});
const onSubmit = handleSubmit(async (values, actions) => {
  try {
    await registrationApi.registration({
      registerRequest: values,
    });
  } catch (e) {
    await handleFormApiErrors(e, globalErrors, actions);
    return;
  }

  toast.success(t('successfully registered'));

  globalErrors.value = [];
  setupClient();
  router.push({ name: 'home' });
});

const loginApi = new LoginApi();

const onGoogleSubmit = handleSubmit(async (values, actions) => {
  let result;
  try {
    result = await loginApi.loginGoogleUrl({
      redirectRequest: { codeword: values.codeword },
    });
  } catch (e) {
    await handleFormApiErrors(e, globalErrors, actions);
    return;
  }
  window.location.href = result.redirectTo;
});
</script>

<template>
  <Signup>
    <template #signup-title>{{ $t(`registerFor`) }} Jelte.Li</template>
    <form @submit="onSubmit">
      <FormErrors :errors="globalErrors" />
      <div class="codeword">
        <LabeledField
          name="codeword"
          :hint="t('codewordHint')"
          :handle-submit="handleSubmit"
          persistent-hint
        ></LabeledField>
      </div>

      <GoogleLoginButton
        :disabled="isSubmitting"
        :click-handler="onGoogleSubmit"
      />

      <span class="or">{{ $t('or') }}</span>
      <LabeledField name="name" />
      <LabeledField name="email" type="email" />
      <LabeledField name="password1" type="password" />
      <LabeledField name="password2" type="password" />
      <Submit name="register" color="normal" :loading="isSubmitting" />
    </form>
    <div class="go-to-register">
      {{ $t('alreadyAccount') }}&nbsp;<router-link to="/login">
        {{ $t('signin') }}</router-link
      >
    </div>
  </Signup>
</template>

<style scoped lang="scss">
.codeword {
  display: flex;
  flex-direction: row;

  svg {
    height: 35px;
    width: 35px;
    margin-bottom: -10px;
    cursor: pointer;

    &:hover {
      fill: gray;
    }

    @include mobile {
      height: 50px;
      width: 50px;
      margin-bottom: -15px;
    }
  }
}

form {
  span.or {
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
  }
  .google-login,
  .google-loading-button {
    display: block;
    margin: $space-s auto;
  }
}

.go-to-register {
  border: 1px solid white;
  border-radius: $radius6;
  min-width: 280px;
  padding: $space-s;
  margin-top: $space-m;
  text-align: center;

  a {
    font-weight: bold;
  }
}
</style>
