/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PasswordChangeRequest,
  PasswordResetConfirmRequest,
  PasswordResetRequest,
  RestAuthDetail,
} from '../models';
import {
    PasswordChangeRequestFromJSON,
    PasswordChangeRequestToJSON,
    PasswordResetConfirmRequestFromJSON,
    PasswordResetConfirmRequestToJSON,
    PasswordResetRequestFromJSON,
    PasswordResetRequestToJSON,
    RestAuthDetailFromJSON,
    RestAuthDetailToJSON,
} from '../models';

export interface PasswordChangeOperationRequest {
    passwordChangeRequest: PasswordChangeRequest;
}

export interface PasswordResetOperationRequest {
    passwordResetRequest: PasswordResetRequest;
}

export interface PasswordResetConfirmOperationRequest {
    passwordResetConfirmRequest: PasswordResetConfirmRequest;
}

/**
 * 
 */
export class PasswordApi extends runtime.BaseAPI {

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     */
    async passwordChangeRaw(requestParameters: PasswordChangeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters.passwordChangeRequest === null || requestParameters.passwordChangeRequest === undefined) {
            throw new runtime.RequiredError('passwordChangeRequest','Required parameter requestParameters.passwordChangeRequest was null or undefined when calling passwordChange.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/password/change/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeRequestToJSON(requestParameters.passwordChangeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     */
    async passwordChange(requestParameters: PasswordChangeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.passwordChangeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     */
    async passwordResetRaw(requestParameters: PasswordResetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters.passwordResetRequest === null || requestParameters.passwordResetRequest === undefined) {
            throw new runtime.RequiredError('passwordResetRequest','Required parameter requestParameters.passwordResetRequest was null or undefined when calling passwordReset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/password/reset/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters.passwordResetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     */
    async passwordReset(requestParameters: PasswordResetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.passwordResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     */
    async passwordResetConfirmRaw(requestParameters: PasswordResetConfirmOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters.passwordResetConfirmRequest === null || requestParameters.passwordResetConfirmRequest === undefined) {
            throw new runtime.RequiredError('passwordResetConfirmRequest','Required parameter requestParameters.passwordResetConfirmRequest was null or undefined when calling passwordResetConfirm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/password/reset/confirm/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetConfirmRequestToJSON(requestParameters.passwordResetConfirmRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     */
    async passwordResetConfirm(requestParameters: PasswordResetConfirmOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.passwordResetConfirmRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
